import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  h3 {
    margin-top: 30px;
  }
`;

export const AddVehicle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;

  button {
    background: white;
    width: 100%;
    color: #1ea418;
    border-radius: 5px;
    border: 1px solid #1ea418;
    font-size: 16px;
    height: 50px;
    outline: none;
    align-self: flex-end;
    transition: 0.15s ease-in-out;

    :disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  @media (max-width: 900px) {
    gap: 20px;
    flex-wrap: wrap;
    max-width: 600px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  input {
    padding: 6px 12px;
    border: 1px solid #ececec;
    outline: none;
    display: block;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;

    ::placeholder {
      color: #bababa;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bababa;
    }

    ::-ms-input-placeholder {
      color: #bababa;
    }

    :read-only {
      background: #eeeeee;
    }
  }

  label {
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    display: block;
  }

  @media (max-width: 900px) {
    max-width: 263px;
  }

  @media (max-width: 650px) {
    max-width: 100% !important;
  }
`;
