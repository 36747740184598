import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Navigation, Pagination } from "swiper";

import { Swiper, useSwiper } from "swiper/react";

import { Container } from "./styles";

const SwiperComponent: React.FC<any> = ({ children, breakpoints }) => {
  return (
    <Container>
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          bulletActiveClass: "swiper-nav-active",
          bulletClass: "swiper-nav",
        }}
        breakpoints={breakpoints}
        rewind
      >
        {children}
        {!!children.length && <Buttons />}
      </Swiper>
    </Container>
  );
};

export default SwiperComponent;

const Buttons: React.FC = () => {
  const swiper = useSwiper();

  return (
    <div className="buttons">
      <button
        onClick={() => swiper.slidePrev()}
        className="left swiper-prev-el"
      >
        <FaArrowLeft />
      </button>
      <button
        className="right swiper-next-el"
        onClick={() => swiper.slideNext()}
      >
        <FaArrowRight />
      </button>
    </div>
  );
};
