import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

import { Wrapper, Container } from "./styles";
import { IProduct } from "../../store/products";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

interface IProps {
  product?: IProduct | null;
}

const Breadcrumb: React.FC<IProps> = ({ product }) => {
  const location = useLocation();

  const currentPathName = useMemo(() => {
    return location.pathname.split("/").filter((item) => item);
  }, [location]);

  const getCurrentPathName = (pathname: string) => {
    switch (pathname) {
      case "agendamentos":
        return {
          path: "/agendamentos",
          name: "Agendamentos",
        };
      case "item-produto":
        return {
          path: "/produtos",
          name: "Produtos",
        };
      case "produtos":
        return {
          path: "/produtos",
          name: "Produtos",
        };
      case "compra":
        return { path: "/compra", name: "Carrinho" };
      case "perguntas-frequentes":
        return { path: "/perguntas-frequentes", name: "Perguntas Frequentes" };
      case "contato":
        return { path: "/contato", name: "Contato" };
      case "perfil":
        return { path: "/perfil", name: "Perfil" };
      case "login":
        return { path: "/login", name: "Login" };
      case "cadastro-usuario":
        return { path: "/cadastro-usuario", name: "Cadastro" };
      case "pagamento":
        return { path: "/pagamento", name: "Pagamento" };
      default:
        return {
          path: "",
          name: product?.title,
        };
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="col-12">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/">Início</Link>

              {currentPathName.map((pathname, index) => {
                if (
                  getCurrentPathName(pathname).path &&
                  index !== currentPathName.length - 1
                ) {
                  return (
                    <Link
                      key={"path" + pathname}
                      to={getCurrentPathName(pathname).path}
                    >
                      {getCurrentPathName(pathname).name}
                    </Link>
                  );
                } else {
                  return (
                    <Typography key={"path" + pathname}>
                      {getCurrentPathName(pathname).name}
                    </Typography>
                  );
                }
              })}
            </Breadcrumbs>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};
export default Breadcrumb;
