import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CadastroDadosPessoais from "../../components/Profile/User";

import Breadcrumb from "../../components/breadcrumb";

const CadastroUsuario = () => {
  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <Breadcrumb />

      <CadastroDadosPessoais />

      <Footer />
    </>
  );
};
export default CadastroUsuario;
