import styled from "styled-components";

export const HeaderWrapper = styled.header`
  width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 0;

  .searchbox {
    position: relative;
    width: 100%;
    z-index: 20;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1200px) {
    padding: 20px;
  }

  @media (max-width: 650px) {
    .dropdown {
      right: 0 !important;
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1160px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;

export const HeaderLeft = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  width: 100%;
  max-width: 754px;

  .search {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  span.icon {
    color: #797a7c;
    position: absolute;
    left: 20px;
    z-index: 33;
  }

  input {
    width: 100%;
    border-radius: 23px;
    border: 1px solid #ececec;
    padding: 15px 48px;
    color: #797a7c;
    outline: none;
    height: 40px;
  }

  @media (max-width: 780px) {
    .search {
      display: none;
    }
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  flex: none;

  a {
    color: #5e5e5e;
    font-size: 14px;
    flex: none;
  }
`;

export const User = styled.div`
  &,
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  @media (max-width: 650px) {
    span.name {
      display: none;
    }
  }
`;

export const SearchBarMobile = styled.div`
  display: none;
  position: relative;
  width: 100%;

  margin-top: 20px;

  span.icon {
    color: #797a7c;
    position: absolute;
    left: 20px;
    z-index: 33;
  }

  input {
    width: 100%;
    border-radius: 23px;
    border: 1px solid #ececec;
    padding: 15px 48px;
    color: #797a7c;
    outline: none;
    height: 40px;
  }

  @media (max-width: 780px) {
    display: flex;
    align-items: center;
  }
`;

export const DropdownNav = styled.nav`
  a,
  button {
    display: flex;
    font-size: 16px;
    padding: 0;
    padding: 10px 15px;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    width: 100%;
  }

  button {
    color: #bf0000;
  }
`;

export const Search = styled.div`
  width: calc(100% - 30px);
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  padding: 20px;
  top: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  max-height: 400px;
  overflow-y: scroll;

  z-index: 120 !important;
`;

export const Product = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 100px;
    width: 80px;
    object-fit: cover;
  }

  p {
    font-size: 16px;
    color: #e64c15;
    text-transform: uppercase;
    margin: 0;
  }
`;
