import styled, { keyframes } from "styled-components";
import { Form as FormikForm } from "formik";
import CustomInput from "../../components/Inputs";
import CustomTextArea from "../../components/Inputs/textarea";

export const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  min-height: 40vh;

  margin: 30px auto;


  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  h1 {
    font-size: 30px;
    margin: 0;
  }

  p {
    margin-top: 20px;
    font-size: 20px;
    color: #5e5e5e;
  }
`;

export const Form = styled(FormikForm)`
  width: 100%;

  margin-top: 20px;

  span {
    display: inline-block;
    font-size: 14px;
    margin-top: 4px;
    color: red;
  }

  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 40px;
  }

  .input-container {
    width: 100%;
    margin-top: 20px;

    label {
      display: inline-block;
      font-size: 16px;
      color: black;
    }
  }

  button {
    margin-top: 20px;
    background: white;
    border-radius: 5px;
    outline: none;
    width: 100%;
    max-width: 120px;
    color: #e64c15;
    border: 1px solid #e64c15;
    height: 50px;
    transition: 0.15s linear;

    :disabled {
      opacity: 0.5;
    }
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 50%;
  margin: 0 auto;

  border-top: 2px solid #e64c15;
  border-bottom: 2px solid #e64c15;
  border-left: 2px solid #e64c15;
  border-right: 2px solid transparent;

  animation: ${spin} infinite 0.7s linear;
`;

export const Input = styled(CustomInput)`
  margin-top: 8px;
  border: 1px solid #ededed;
  border-radius: 5px;
  outline: none;
  background: white;
  padding: 0 16px;
  height: 31px;
  width: 100%;
`;

export const Textarea = styled(CustomTextArea)`
  margin-top: 8px;
  border: 1px solid #ededed;
  border-radius: 5px;
  outline: none;
  background: white;
  padding: 16px;
  width: 100%;
  resize: none;
  height: 150px;
`;
