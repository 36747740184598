import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;

  table th {
    background: var(--green);
    color: #fff;
    border: none;
  }

  button {
    color: white;
    padding: 0 15px;
    background: #2020e6;
    border: none;
    border-radius: 5px;
  }
`;
