import api from "./api";

interface ISignin {
  email: string;
  password: string;
}

export async function signIn(data: ISignin) {
  const response: any = await api.post("/authenticate", data);
  return response.data;
}
