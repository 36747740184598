import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listProducts } from "../../services/products.service";
import { RootState } from "../index";

export interface IProduct {
  installments: number;
  technicalInformation: string | null | undefined;
  _id: string;
  title: string;
  image: string;
  price: number;
  isActive: boolean;
  description: string;
  amount: number;
  category: string;
  images?: any[];
  qtdServices?: number;

  organization: {
    _id: string;
    name: string;
    companyName: string;
    address: any;
  };
}

export interface IProductResponse {
  currentPage: number;
  filteredItems: number;
  items: IProduct[];
  itemsTotal: number;
  paginate: number;
}

export interface IProductState {
  data: IProductResponse | null;
  loading: boolean;
}

const initialState: IProductState = {
  data: null,
  loading: false,
};

interface IReqProduct {
  rowsPerPage?: number;
  page?: number;
  category?: string;
  userId: string;
  carYear?: string;
  carModel?: string;
  carBrand?: string;
  productType?: string;
  cep?: string;
}

export const productsList = createAsyncThunk(
  "products/listProducts",
  async ({
    page = -1,
    rowsPerPage,
    category,
    carYear,
    carModel,
    carBrand,
    userId,
    productType,
    cep,
  }: IReqProduct) => {
    const response = await listProducts({
      page,
      rowsPerPage,
      category,
      carYear,
      carModel,
      carBrand,
      productType,
      cep,
    });

    return response;
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(productsList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
  },
});

export const productSelector = (state: RootState) => state.products;

export default productSlice.reducer;
