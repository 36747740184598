import styled, { keyframes } from "styled-components";

import CustomInput from "../Inputs";

const spin = keyframes`
  from {
    transform: translate(0);
  }

  to {
    transform: translate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 400px;

  margin: 30px auto;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  button {
    padding: 6px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    outline: none;
    transition: 0.15s linear;

    :disabled {
      opacity: 0.4;
    }

    background: #e64c15;
    color: white;

    margin-top: 20px;
  }
`;

export const Input = styled(CustomInput)`
  padding: 6px 12px;
  border: 1px solid #ececec;
  outline: none;
  display: block;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;

  ::placeholder {
    color: #bababa;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #bababa;
  }

  ::-ms-input-placeholder {
    color: #bababa;
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  span.error {
    display: block;
    margin-top: 3px;
    color: red;
    font-size: 12px;
  }

  label {
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    display: block;
  }
`;

export const Spinner = styled.div`
  animation: ${spin};
`;
