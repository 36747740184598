import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 15vh;
  margin: 50px auto;
  text-align: center;
  max-width: 1160px;
  background: #fff;
  border: 1px solid #eaecee;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;
