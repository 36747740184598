import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1160px;

  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
`;
