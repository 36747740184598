import { useCallback, useEffect, useMemo, useState } from "react";
import AgendamentoCard from "../../components/agendamentos/card";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Breadcrumb from "../../components/breadcrumb";

import { Link, useNavigate } from "react-router-dom";
import Produto from "../../components/product/ProductsSwiper";
import api from "../../services/api";
import { useAppSelector } from "../../hooks";
import { Container } from "./styles";
import LoadingPage from "../../components/LoadingPage";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";


interface IProductImage {
  path: string;
  name: string;
  mimetype: string;
  filepath: string;
  image64: string;
}

interface IShopCartProduct {
  total: number;
  amount: number;
  startDate: Date;
  saleProducts: {
    _id: string;
    title: string;
    images: IProductImage[];
  };
  _id: string;
}

interface IShopCart {
  _id: string;
  products: IShopCartProduct[];
}

export interface ISchedule {
  _id: string;
  organization: {
    _id: string;
    name: string;
    companyName: string;
    address: {
      _id: string;
      street: string;
      district: string;
      complement: string;
      country: string;
      state: string;
      city: string;
      zipcode: string;
      number: number;
    }[];
  };
  user: string;
  status: string;
  canceledReason: string;
  startDate: Date;
  endDate: Date;
  saleItems: {
    _id: string;
    total: number;
    products: {
      _id: string;
      title: string;
      images: {
        path: string;
        name: string;
        mimetype: string;
        filepath: string;
        image64: string;
      }[];
    }[];
    amount: number;
    installments: number;
    installmentValue: number;
    user: string;
    organization: string;
    status: string;
    canceledReason: string;
    isActive: boolean;
    shopCart: string;
    id_pay: string;
    createdAt: Date;
    updatedAt: Date;
  };
  shopCart: IShopCart;
  createdAt: string;
}

const Agendamentos = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ISchedule[]>([]);

  const token = useAppSelector((state) => state.token);
  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMyAppoints = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`/schedules/user`);
      setData(response?.data?.data?.items);
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    handleMyAppoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const renderSchedules = useMemo(() => {
    return data?.map((item: ISchedule, index: number) => {
      const date = format(
        parseISO(item?.startDate.toString()),
        "d 'de' LLLL 'de' yyyy",
        {
          locale: ptBR,
        }
      );

      return (
        <AgendamentoCard
          key={item?._id || index}
          organization={item?.organization?.name}
          date={date}
          status={item?.status}
          total={item?.saleItems?.total}
          saleItems={item?.saleItems}
          shopCart={item?.shopCart}
        />
      );
    });
  }, [data]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Header />
      <section className="breadcrumbs">
        <Breadcrumb />
      </section>
      <Container>
        <div className="row">
          <h3>Meus agendamentos</h3>
          <div className="col-12">{renderSchedules}</div>
        </div>

        <div className="col-12 title-section" style={{ padding: "0 20px" }}>
          <h3>Outros Produtos</h3>
          <Link to="/">
            <span>Ver Mais</span>
          </Link>
        </div>

        <div className="row">
          <Produto />
        </div>
      </Container>
      <Footer />
    </>
  );
};
export default Agendamentos;
