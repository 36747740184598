import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;

  > span {
    font-size: 16px;
    color: #5e5e5e;
    display: block;
  }

  div {
    display: flex;
    border: 1px solid #ececec;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;

    select {
      border: none;
      outline: none;
      padding: 0 10px;
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      color: #bababa;
    }

    span.icon {
      background: rgba(94, 94, 94, 0.22);
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
