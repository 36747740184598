import Cards from "react-credit-cards";
import MaskedInput from "react-text-mask";
import { useDebouncedCallback } from "use-debounce";
import cep from "cep-promise";

import removeAllNonNumberCharacters from "../../../utils/removeAllNonNumberCharacters";
import {
  AddressWrapper,
  Column,
  CreditCardContainer,
  PixContainer,
} from "./styles";
import { handleToast } from "../../../utils/handleToast";

interface IData {
  cardNumber: string;
  cvc: string;
  expiry: string;
}

interface IAddress {
  zipcode: string;
  street: string;
  complement: string;
  number: string;
}

interface IProps {
  data: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  name: string;
  cpfCnpj: string;
  products: any[];
  installments: number;
  totalInstallments: number;
  setInstallments: (v: number) => void;
  setName: (v: string) => void;
  setCpfCnpj: (v: string) => void;
  value?: number;
  address: IAddress;
  setAddress: React.Dispatch<React.SetStateAction<IAddress>>;
}

const CreditCard: React.FC<IProps> = ({
  data,
  setData,
  name,
  products,
  installments,
  setInstallments,
  value,
  totalInstallments,
  setName,
  setCpfCnpj,
  cpfCnpj,
  address,
  setAddress,
}) => {
  // const productSelector = useAppSelector((state) => state.products);

  // const findProduct = (id: string) => {
  //   return productSelector?.data?.items?.find((item) => item._id === id);
  // };
  // const totalInstallments = useMemo(() => {
  //   if (products?.length) {
  //     const allInstallments = products?.map(
  //       (product) => findProduct(product.saleProducts)?.installments || 1
  //     );

  //     return Math.max(...allInstallments);
  //   }

  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [products]);

  const formatPrice = (price: string | number) => {
    if (!price) return "";

    return price.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getDebouncedCep = useDebouncedCallback(async (c) => {
    if (removeAllNonNumberCharacters(c).length === 8) {
      try {
        const res = await cep(removeAllNonNumberCharacters(c));
        setAddress({ ...address, street: res.street });
      } catch (e) {
        handleToast(true, "Não foi possível trazer CEP");
      }
    }
  }, 1000);

  return (
    <CreditCardContainer>
      <Cards
        name={name}
        expiry={data.expiry}
        cvc={data.cvc}
        number={data.cardNumber}
        locale={{ valid: "Válido até" }}
        placeholders={{ name: "NOME NO CARTÃO" }}
      />
      <div>
        <PixContainer>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome como no cartão"
          />
          <MaskedInput
            name="cpfCnpj"
            value={cpfCnpj}
            onChange={(e) => setCpfCnpj(e.target.value)}
            placeholder="CPF"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
            ]}
          />
        </PixContainer>
        <MaskedInput
          name="cardNumber"
          value={data.cardNumber}
          onChange={(e) => setData({ ...data, cardNumber: e.target.value })}
          placeholder="Número"
          type="tel"
          style={{ width: "100%" }}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
        />
        <div className="form">
          <MaskedInput
            placeholder="Validade"
            mask={[/\d/, /\d/, "/", /\d/, /\d/]}
            type="tel"
            onChange={(e) => setData({ ...data, expiry: e.target.value })}
            value={data.expiry}
          />
          <input
            placeholder="CVV"
            type="tel"
            onChange={(e) => setData({ ...data, cvc: e.target.value })}
            value={data.cvc}
          />
        </div>
        <select
          value={installments}
          onChange={(e) => setInstallments(Number(e.target.value))}
          style={{ width: "100%" }}
        >
          {[...Array(totalInstallments)].map((n, index) => (
            <option value={index + 1}>
              {index + 1} x de {formatPrice((value || 0) / (index + 1))}
            </option>
          ))}
        </select>
      </div>
      <Column>
        <h3>Endereço</h3>
        <AddressWrapper>
          <MaskedInput
            name="zipcode"
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
            placeholder="CEP"
            type="tel"
            onChange={(e) => {
              getDebouncedCep(e.target.value);
              setAddress({ ...address, zipcode: e.target.value });
            }}
            value={address.zipcode}
          />
          <input
            name="street"
            placeholder="Rua"
            onChange={(e) => setAddress({ ...address, street: e.target.value })}
            value={address.street}
          />
          <div>
            <input
              name="number"
              placeholder="Número"
              type="tel"
              onChange={(e) =>
                setAddress({ ...address, number: e.target.value })
              }
              value={address.number}
              style={{ width: 205 }}
            />
            <input
              name="complement"
              placeholder="Complemento"
              onChange={(e) =>
                setAddress({ ...address, complement: e.target.value })
              }
              value={address.complement}
              style={{ width: 205 }}
            />
          </div>
        </AddressWrapper>
      </Column>
    </CreditCardContainer>
  );
};

export default CreditCard;
