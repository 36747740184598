import { useField } from "formik";
import MaskedInput from "react-text-mask";

const Input: React.FC<any> = (props) => {
  const [field, meta] = useField(props);

  return (
    <>
      {props.mask ? (
        <MaskedInput {...field} {...props} />
      ) : (
        <input {...field} {...props} />
      )}
      {!!meta.error && <span className="error">{meta.error}</span>}
    </>
  );
};

export default Input;
