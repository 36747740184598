import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { userFetch } from "../../store/user";
import LoadingPage from "../LoadingPage";
import { clearRedirect, saveRedirect } from "../../store/redirect";

const ProtectedRoute: React.FC<any> = ({
  element,
  authenticated,
  unauthenticated,
}: any) => {
  const [loading, setLoading] = useState(true);
  const token = useAppSelector((state) => state.token);
  const user = useAppSelector((state) => state.user);
  const redirect = useAppSelector((state) => state.redirect);
  const logged = token.data && user?.data?.user;

  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    const fetch = async () => {
      await dispatch(userFetch());

      setLoading(false);
    };

    fetch();
  }, [token, dispatch]);

  useEffect(() => {
    if ((!user.data && authenticated) || (!user.data && unauthenticated)) {
      dispatch(saveRedirect(location.pathname));
    }
  }, [user.data, authenticated, unauthenticated, location.pathname, dispatch]);

  useEffect(() => {
    if ((user.data && authenticated) || (user.data && unauthenticated)) {
      dispatch(clearRedirect());
    }
  }, [user.data, authenticated, unauthenticated, dispatch]);

  if (logged && redirect.path) return <Navigate to={redirect.path} />;

  if (loading) return <LoadingPage />;
  if (unauthenticated) return element;
  if (!logged && authenticated) return <Navigate to="/login" />;
  if (!authenticated && logged) return <Navigate to="/" />;

  return element;
};

export default ProtectedRoute;
