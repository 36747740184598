import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;

  margin: 30px auto;

  min-height: 40vh;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  h1 {
    font-size: 30px;
    margin: 0;
  }

  > div {
    margin-top: 20px;
  }

  div {
    box-shadow: none;
    padding: 0;
    background: transparent;

    &.MuiAccordionSummary-root {
      max-width: fit-content;
      gap: 20px;
      * {
        font-weight: 700;
      }
    }
  }
`;
