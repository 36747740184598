import api from "./api";

interface IReq {
  rowsPerPage?: number;
  page?: number;
  category?: string;
  name?: string;
  carYear?: string;
  carModel?: string;
  carBrand?: string;
  productType?: string;
  cep?: string;
}

export async function listProducts({
  rowsPerPage,
  page,
  category,
  name,
  carYear,
  carModel,
  carBrand,
  productType,
  cep,
}: IReq) {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const response: any = await api.get("/products-sale", {
        params: {
          perPage: rowsPerPage,
          page,
          category,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          title: name,
          carYear,
          carModel,
          carBrand,
          productType,
          cep,
        },
      });

      return response.data;
    });
  }
  const response: any = await api.get("/products-sale", {
    params: {
      perPage: rowsPerPage,
      page,
      category,
      title: name,
      carYear,
      carModel,
      carBrand,
      productType,
      cep,
    },
  });

  return response.data;
}
