import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;

  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;
