import { useEffect, useState } from "react";
import { Bottom, Card, Container } from "./styles";
import { listProducts } from "../../services/products.service";
import { handleToast } from "../../utils/handleToast";
import { Link } from "react-router-dom";
import { formatPrice } from "../../utils/formatPrice";
import NoImage from "../../img/no-image-lg.png";
import { useAppSelector } from "../../hooks";
import ReactLoading from "react-loading";

const ProductsList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined as any);
  const token = useAppSelector((state) => state.token);
  const address = useAppSelector((state) => state.address.data);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const res = await listProducts({
          page: 1,
          rowsPerPage: 16,
          cep: address?.zipcode || address?.cep,
        });

        if (!(res as any).error) {
          setData(res?.data?.items);
        } else {
          handleToast(true, "Não foi possível trazer produtos");
        }
      } catch (e) {
        handleToast(true, "Não foi possível trazer produtos");
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [address?.cep, address?.zipcode]);

  if (loading) {
    return (
      <ReactLoading
        type={"bars"}
        color={"#1ea418"}
        className="loading-center"
        height="50px"
        width="50px"
      />
    );
  }

  return (
    <Container>
      {data?.map((item: any) => (
        <Card key={item._id}>
          <Link to={token ? `/item-produto/${item._id}` : "/login"}>
            {item.images != null ? (
              <img
                src={
                  item?.images[0]?.image64
                    ? `data:image/png;base64, ${item?.images[0]?.image64}`
                    : NoImage
                }
                alt={item?.title}
              />
            ) : (
              <img src={NoImage} alt={item?.title} />
            )}

            <Bottom>
              <h3>{item.title}</h3>
              {/* <p className="brand">
                Vendedor: <span>{item.organization?.name || " "}</span>
              </p> */}
              <p className="price-over-time">
                {item?.installments || 1}x de{" "}
                {formatPrice(item.price / item?.installments || 1)}
              </p>
              <p className="total-price">{formatPrice(item.price)}</p>
            </Bottom>
          </Link>
        </Card>
      ))}
    </Container>
  );
};

export default ProductsList;
