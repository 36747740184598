import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #2020e6;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  gap: 20px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;

  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavMenu = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 250px;

  a {
    font-size: 16px;
    color: initial;
    padding: 10px 30px;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Vehicles = styled.div`
  width: 100%;
  max-width: 1050px;

  * {
    z-index: 11;
  }

  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
  align-items: center;

  button {
    color: white;
    background: #e64c15;
    border-radius: 5px;
    height: 30px;
    /* height: 20px; */
    font-size: 16px;
    outline: none;
    border: none;
    margin-left: auto;
    width: 150px;

    @media (max-width: 900px) {
      margin-left: initial;
    }

    :disabled {
      opacity: 0.7;
    }
  }
`;
