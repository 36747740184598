import { useState, useRef, useEffect } from "react";

import { Container, Menu } from "./styles";

const Dropdown: React.FC<any> = ({ children, button, className }) => {
  const [open, setOpen] = useState(false);
  const ref: any = useRef(null);

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref?.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={ref}>
      <button onClick={() => setOpen(!open)}>{button}</button>
      {open && <Menu className={className}>{children}</Menu>}
    </Container>
  );
};

export default Dropdown;
