import { useField } from "formik";

const Textarea: React.FC<any> = (props) => {
  const [field, meta] = useField(props);

  return (
    <>
      <textarea {...field} {...props} />
      {!!meta.error && <span className="error">{meta.error}</span>}
    </>
  );
};

export default Textarea;
