import { Formik, Form } from "formik";
import api from "../../services/api";
import { Input, InputContainer, Container } from "./styles";

const PasswordRecoveryForm: React.FC = () => {
  const sendEmail = async ({ email }: any) => {
    await api.patch("resetpassword", { data: { email } });
  };

  return (
    <Container>
      <h2>Recuperar a senha</h2>
      <p>Entre com seu e-mail para mandarmos um e-mail de recuperação.</p>
      <Formik initialValues={{ email: "" }} onSubmit={sendEmail}>
        <Form>
          <InputContainer>
            <Input name="email" placeholder="E-mail" type="email" />
          </InputContainer>
          <button>Enviar</button>
        </Form>
      </Formik>
    </Container>
  );
};

export default PasswordRecoveryForm;
