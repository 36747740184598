import styled, { keyframes } from "styled-components";
import { Form as FormikForm } from "formik";
import CustomInput from "../Inputs";

const spin = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 400px;
  margin: auto;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;

  div.form-row {
    display: flex;
    column-gap: 100px;
    row-gap: 20px;

    :not(:first-child) {
      margin-top: 20px;
    }
  }

  > a {
    font-size: 14px;
    display: flex;
    width: fit-content;
    margin-top: 10px;
    opacity: 0.9;
  }
`;

export const Input = styled(CustomInput)`
  padding: 6px 12px;
  border: 1px solid #ececec;
  outline: none;
  display: block;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;

  ::placeholder {
    color: #bababa;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #bababa;
  }

  ::-ms-input-placeholder {
    color: #bababa;
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  span.error {
    display: block;
    margin-top: 3px;
    color: red;
    font-size: 12px;
  }

  label {
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    display: block;
  }
`;
export const FlexContainer = styled.div`
  width: 100%;
  display:flex; 
  
  span.error {
    display: block;
    color: red;
    font-size: 12px;
    position: absolute;
    transform: translateY(3rem);
  }

  label {
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    display: block;
  }
  
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 20px;
  justify-content: center;

  button,
  a {
    padding: 15px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    outline: none;
    transition: 0.15s linear;

    :disabled {
      opacity: 0.4;
    }

    :last-child {
      color: #e64c15;
      background: white;
      border: 1px solid #e64c15;
    }

    :first-child {
      background: #e64c15;
      color: white;
    }
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 50%;

  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid transparent;

  animation: 1s linear infinite ${spin};
`;
