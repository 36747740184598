import NavbarMenu from "../Navbar";
import { useState, useEffect, useMemo } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  FaUserAlt,
  FaShoppingCart,
  FaChevronDown,
  FaSearch,
} from "react-icons/fa";
import { useDebouncedCallback } from "use-debounce";
import { Badge } from "@mui/material";

import {
  HeaderWrapper,
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  User,
  SearchBarMobile,
  DropdownNav,
  Search,
  Product,
} from "./styles";

import Logo from "../../img/lubbri.png";
import Dropdown from "../Dropdown";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useCallback } from "react";
import { clearUser } from "../../store/user";
import { clearToken } from "../../store/token";
import { clearShopCart, userShopCart } from "../../store/shop-cart";
import { listProducts } from "../../services/products.service";

import NoImage from "../../img/no-image-lg.png";
import { handleToast } from "../../utils/handleToast";

interface IProps {
  loadingPage?: boolean;
}

const Header: React.FC<IProps> = ({ loadingPage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.user);

  const { data: shopCartData } = useAppSelector((state) => state.shopCart);

  const [filteredProducts, setFilteredProducts] = useState([] as any);
  const [searchValue, setSearchValue] = useState("");

  const logout = useCallback(() => {
    dispatch(clearUser());
    dispatch(clearToken());
    dispatch(clearShopCart());

    handleToast(false, "Logout realizado.");

    navigate("/");
  }, [dispatch, navigate]);

  const initShopCart = useCallback(() => {
    if (user) {
      dispatch(userShopCart());
    }
  }, [user, dispatch]);

  useEffect(() => {
    initShopCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalItemsInShopCart: number = useMemo(() => {
    return shopCartData?.items?.products ? shopCartData.items.products.length : 0;
  }, [shopCartData]);

  const debouncedSearch = useDebouncedCallback(async (value) => {
    setSearchValue(value);
    if (value) {
      const res = await listProducts({ name: value });

      setFilteredProducts(res.data.items);
    }
  }, 500);

  const userComponent = () => {
    const DropdownButton = () => (
      <User>
        <FaUserAlt />
        <span className="name">{user?.data?.user?.name}</span>
        <FaChevronDown />
      </User>
    );

    if (user?.data) {
      return (
        <Dropdown button={<DropdownButton />} className="dropdown">
          <DropdownNav>
            <Link to="/perfil">Perfil</Link>
            <Link to="/agendamentos">Agendamentos</Link>
            <button onClick={() => logout()}>Sair</button>
          </DropdownNav>
        </Dropdown>
      );
    }

    return (
      <User>
        <Link to="/login">
          <FaUserAlt />
          <span className="name">Login/Cadastrar</span>
        </Link>
      </User>
    );
  };

  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <HeaderLeft>
            <Link to="/">
              <img src={Logo} alt="lubbri" />
            </Link>
            <div className="search">
              <span className="icon">
                <FaSearch />
              </span>
              <div className="searchbox">
                <input
                  type="text"
                  placeholder="Procurar"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setSearchValue("");
                    }
                    debouncedSearch(e.target.value);
                  }}
                />
                {!!searchValue && (
                  <Search>
                    {filteredProducts.map((product: any) => {
                      return (
                        <Product
                          key={"search" + product._id}
                          onClick={() => {
                            navigate(`/item-produto/${product._id}`);
                          }}
                        >
                          <img
                            src={
                              product?.images[0]?.image64
                                ? `data:image/png;base64, ${product?.images[0]?.image64}`
                                : NoImage
                            }
                            alt="Imagem do produto"
                          />
                          <p>{product.title || "Produto"}</p>
                        </Product>
                      );
                    })}
                  </Search>
                )}
              </div>
            </div>
          </HeaderLeft>

          <HeaderRight>
            {userComponent()}
            <Link to="/compra">
              <Badge badgeContent={totalItemsInShopCart} color='error'>
                <FaShoppingCart size={18} />
              </Badge>
            </Link>
          </HeaderRight>
        </HeaderContainer>
        <SearchBarMobile>
          <span className="icon">
            <FaSearch />
          </span>
          <div className="searchbox">
            <input
              type="text"
              placeholder="Procurar"
              onChange={(e) => {
                if (!e.target.value) {
                  setSearchValue("");
                }
                debouncedSearch(e.target.value);
              }}
            />
            {!!searchValue && (
              <Search>
                {filteredProducts.map((product: any) => {
                  return (
                    <Product
                      key={"search" + product?._id}
                      onClick={() => navigate(`/item-produto/${product?._id}`)}
                    >
                      <img
                        src={
                          product?.images[0]?.image64
                            ? `data:image/png;base64, ${product?.images[0]?.image64}`
                            : NoImage
                        }
                        alt="Imagem do produto"
                      />
                      <p>{product?.title || "Produto"}</p>
                    </Product>
                  );
                })}
              </Search>
            )}
          </div>
        </SearchBarMobile>
      </HeaderWrapper>
      <NavbarMenu loadingPage={loadingPage} />
    </>
  );
};
export default Header;
