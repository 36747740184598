import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 50px auto;
  max-width: 1160px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;
