import styled from "styled-components";
import { Modal } from "@mui/material";

export const ModalCustom = styled(Modal)`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  border: none;
  outline: none;
`;

export const Button = styled.button`
  width: 100%;
  max-width: fit-content;

  display: flex;
  gap: 10px;

  transition: 0.15s;
  background: transparent;
  color: white;
  border: none;
  outline: none;

  padding: 10px 10px 0;
  border-radius: 3px;

  p,
  span {
    color: white;
    width: fit-content;
    margin: 0;
    font-size: 14px;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }
`;

export const AddressPickerContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  background: white;
  padding: 30px;
  border-radius: 3px;
  border: none;
  outline: none;
  height: 90vh;

  overflow: auto;

  > a {
    margin-top: 15px;
    display: block;
    font-weight: 600;
    color: #2020e6;
    font-size: 17px;
  }

  h2 {
    padding-bottom: 15px;
    width: 100%;

    font-size: 24px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #333333;
  }

  h3 {
    color: #333333;
    font-size: 18px;
    margin-top: 30px;
  }
`;

export const AddressOption = styled.button<{ selected?: boolean }>`
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  display: flex;
  gap: 40px;
  align-items: center;
  outline: none;

  background: ${({ selected }) => (selected ? "#2020e60a" : "white")};
  margin: 10px 0;

  div:not(.radio) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 3px;
      color: #333333;
      font-weight: 500;
      font-size: 17px;
    }
  }

  .radio {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid
      ${({ selected }) => (selected ? "#2020e6" : "rgba(0, 0, 0, 0.8)")};

    ::after {
      content: "";
      background: #2020e6cc;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      display: ${({ selected }) => (selected ? "flex" : "none")};
    }
  }
`;

export const CEPContainer = styled.div`
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 10px;

  .input-container {
    position: relative;
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    button {
      position: absolute;
      width: 60px;
      color: white;
      background: #2020e6cc;
      border: none;
      outline: none;
      right: 0;
      font-size: 13px;
      height: 100%;

      :disabled {
        opacity: 0.8;
      }
    }
  }

  input {
    height: 40px;
    width: 240px;
    padding: 16px;
    font-size: 16px;
    outline: none;
    transition: 0.15s;
    border: none;

    :focus {
      border: #2020e6cc;
    }
  }

  .address-content {
    margin: 3px;
    color: #333333;
    font-weight: 500;
    font-size: 17px;
    margin-top: 20px;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;

  margin-top: 30px;
  width: 100%;
  justify-content: flex-end;

  button {
    width: 120px;
    border-radius: 5px;
    padding: 5px 0;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;

    :first-child {
      background: white;
      color: #2020e6;
      border: 1px solid #2020e6;
    }

    :last-child {
      background: #2020e6;
      border: 1px solid #2020e6;
      color: white;
    }
  }
`;
