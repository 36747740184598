import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { signIn } from "../../services/token.service";
import { RootState } from "../index";

export interface TokenState {
  data: string | null;
  loading: boolean;
}

const initialState: TokenState = {
  data: null,
  loading: false,
};
interface ISignin {
  email: string;
  password: string;
}
export const loginToken = createAsyncThunk(
  "token/saveToken",
  async (data: ISignin) => {
    const response = await signIn(data);
    return response;
  }
);

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    saveToken: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
    },
    clearToken: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginToken.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
  },
});

export const { saveToken, clearToken } = tokenSlice.actions;

export const tokenSelector = (state: RootState) => state.token.data;
export const tokenLoadingSelector = (state: RootState) => state.token.loading;

export default tokenSlice.reducer;
