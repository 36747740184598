import { SwiperSlide } from "swiper/react";
import { useEffect, useCallback, useState } from "react";

import { Link } from "react-router-dom";

import { Container, Bottom, NotFound } from "./styles";
import { IProduct } from "../../../store/products";

import SwiperComponent from "../../Swiper";
import NoImage from "../../../img/no-image-lg.png";
import ReactLoading from "react-loading";
import { handleToast } from "../../../utils/handleToast";
import { listProducts } from "../../../services/products.service";

const Produto = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined as any);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const res = await listProducts({ page: 1, rowsPerPage: 16 });

        if (!(res as any).error) {
          setData(res?.data?.items);
        } else {
          handleToast(true, "Não foi possível trazer produtos");
        }
      } catch (e) {
        handleToast(true, "Não foi possível trazer produtos");
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, []);

  const breakpoints = {
    "1180": {
      slidesPerView: 4,
    },
    "985": {
      slidesPerView: 3,
    },
    "787": {
      slidesPerView: 2,
    },
  };

  const formatPrice = (price: string | number) => {
    if (!price) return "";

    return price.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const renderItems = useCallback((items: any) => {
    if (items && items?.length) {
      return items.map((item: IProduct) => {
        return (
          <SwiperSlide key={item?._id}>
            <Container>
              <Link to={`/item-produto/${item?._id}`}>
                {item?.images?.length ? (
                  <img
                    src={
                      item?.images[0]?.image64
                        ? `data:image/png;base64, ${item?.images[0]?.image64}`
                        : item?.images[0]?.image
                    }
                    alt="product"
                  />
                ) : (
                  <img src={NoImage} alt="produtos" />
                )}

                <Bottom>
                  <h3>{item?.title}</h3>
                  {/* <p className="brand">
                    Vendedor: <span>{item?.organization?.name || " "}</span>
                  </p> */}
                  <p className="price-over-time">
                    {item?.installments || 1}x de{" "}
                    {formatPrice(item?.price / (item?.installments || 1))}
                  </p>
                  <p className="total-price">{formatPrice(item?.price)}</p>
                </Bottom>
              </Link>
            </Container>
          </SwiperSlide>
        );
      });
    }

    return [];
  }, []);

  if (loading) {
    return (
      <ReactLoading
        type={"bars"}
        color={"#1ea418"}
        className="loading-center"
        height="50px"
        width="50px"
      />
    );
  }

  if (!loading && !data?.length) {
    return <NotFound>Nenhum produto encontrado.</NotFound>;
  }

  return (
    <>
      <SwiperComponent breakpoints={breakpoints}>
        {!!data.length && renderItems(data)}
      </SwiperComponent>
      <div
        className="controls-carousel-prod"
        style={{ marginBottom: "20px" }}
      ></div>
    </>
  );
};
export default Produto;
