import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

export interface AddressState {
  user: any;
  street: ReactNode;
  district: ReactNode;
  city: ReactNode;
  state: ReactNode;
  data: IAddress | null;
  loading: boolean;
  zipcode?: string;
  cep?: string;
}

export interface IAddress {
  street?: string;
  city: string;
  state: string;
  district: string;
  cep: string;
  _id?: string;
  user?: string | null;
  rua?: string;
  number?: string;
  complement?: string;
  zipcode?: string;
}

const initialState: AddressState = {
  data: null,
  loading: false,
  user: undefined,
  street: undefined,
  district: undefined,
  city: undefined,
  state: undefined,
};

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    saveAddress: (state, action: PayloadAction<IAddress>) => {
      state.data = action.payload;
    },
    clearAddress: (state) => {
      state.data = null;
    },
  },
});

export const { saveAddress, clearAddress } = addressSlice.actions;

export const addressSelector = (state: RootState) => state.address.data;
export const addressLoadingSelector = (state: RootState) =>
  state.address.loading;

export default addressSlice.reducer;
