import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  button {
    display: flex;
    background: none;
    border: none;
    outline: none;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  background: white;
  min-width: 180px;
  border: 1px solid #ececec;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background: white;
  z-index: 9999;
`;
