import styled from "styled-components";
import { Form as FormikForm } from "formik";
import CustomInput from "../../Inputs";

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-top: 30px;

  h3 {
    font-size: 18px;
  }

  button.add {
    background: var(--green);
    color: white;
    padding: 5px 20px;
    font-weight: 500;
    border-radius: 3px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border: none;
    outline: none;
  }

  button.remove {
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: red;
    border: none;
    outline: none;
    margin-left: auto;
    border-radius: 3px;
  }

  div.form-row {
    display: flex;
    column-gap: 30px;
    row-gap: 20px;

    :not(:first-child) {
      margin-top: 20px;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 800px) {
    div.form-row {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 600px) {
    div.form-row {
      flex-direction: column;
    }
  }
`;

export const Input = styled(CustomInput)`
  padding: 6px 12px;
  border: 1px solid #ececec;
  outline: none;
  display: block;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;

  transition: 0.15s linear;

  ::placeholder {
    color: #bababa;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #bababa;
  }

  ::-ms-input-placeholder {
    color: #bababa;
  }
`;

export const InputContainer = styled.div`
  /* width: 100%; */
  /* min-width: 250px; */
  flex: 1 1 40%;

  span.error {
    display: block;
    margin-top: 3px;
    color: red;
    font-size: 12px;
  }

  label {
    font-size: 16px;
    line-height: 21px;
    color: #5e5e5e;
    display: block;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  margin-top: 55px;
  justify-content: center;

  margin-bottom: 55px;

  button {
    padding: 15px 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 122px;
    border: none;
    outline: none;

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    :first-child {
      color: #e64c15;
      background: white;
      border: 1px solid #e64c15;
    }

    :last-child {
      background: #e64c15;
      color: white;
    }
  }

  @media (max-width: 650px) {
    margin: 30px 0;
  }
`;
