import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from{
    transform: rotate(0);
  }

  to{
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const Container = styled.main`
  width: 100%;

  padding: 30px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 30px;
`;

export const Left = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  gap: 30px;
  height: 100%;
  max-height: 420px;

  @media (max-width: 700px) {
    flex-direction: column;
    max-height: initial;
  }

  img {
    width: 100%;
    max-width: 327px;
  }
`;

export const ProductData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span.brand {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }

  h3.title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 12px;
    margin-bottom: 0;
  }

  span.price {
    font-size: 18px;
    font-weight: medium;
    line-height: 24px;
    display: block;
    margin-top: 20px;
  }

  small {
    font-size: 14px;
    margin-top: 3px;
  }

  div.company {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 7px;
  }
`;

export const Right = styled.div`
  span.price {
    font-size: 30px;
    color: #1ea418;
    line-height: 21px;
    font-weight: 500;
    display: block;
  }

  span.parc {
    font-size: 18px;
    line-height: 21px;
    margin-top: 5px;
    display: block;
    margin-top: 10px;
  }

  h4 {
    color: #5e5e5e;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  div.calendar {
    margin-top: 30px;

    > button {
      background: #e64c15;
      color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: none;
      outline: none;
      height: 50px;

      margin-top: 30px;

      :disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
`;

export const Spinner = styled.div`
  width: 15px;
  height: 15px;

  animation: ${spin} 1s linear infinite;

  border-top: 3px solid white;
  border-left: 3px solid white;
  border-right: 3px solid white;
  border-bottom: 3px solid transparent;

  border-radius: 50%;
`;

export const ImageComponent = styled.div`
  display: flex;
  /* flex-flow: row wrap; */
  gap: 20px;
  max-height: 420px;

  @media (max-width: 700px) {
    flex-flow: column;
    gap: 10px;
  }

  img {
    object-fit: fill;
  }

  > img {
    width: 327px;
    height: 420px;
  }

  /* > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    button,
    img {
      height: 50px;
      width: 50px;
    }

    button {
      border-radius: 5px;
      outline: none;
      display: block;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0;
    }
  } */
`;

export const ImageButtonWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 700px) {
    flex-flow: row wrap;
  }

  button,
  img {
    height: 50px;
    width: 50px;
  }

  button {
    border-radius: 5px;
    outline: none;
    display: block;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0;
  }
`;
