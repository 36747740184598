import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 650px;

  &:not(:first-child) {
    margin-top: 30px;
  }

  display: flex;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  gap: 30px;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  .profile {
    display: flex;
    padding: 10px;
    border-radius: 50%;
    background: #ececec;
    justify-content: center;
    align-items: center;
  }

  .right {
    display: flex;
    flex-direction: column;

    > span.name {
      color: #e64c15;
      font-weight: 700;
    }

    p {
      margin-top: 25px;
      font-size: 16px;
    }
  }
`;
