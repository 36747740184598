import * as React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "./tabsinfo.css";
import Comment from "../Comment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps {
  technicalInformation?: string | null;
  description?: string | null;
  comments?: any;
}

const TabsInfo: React.FC<IProps> = ({
  technicalInformation,
  description,
  comments,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="tabsinfo">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Descrição" {...a11yProps(0)} />
            <Tab label="Informações técnicas" {...a11yProps(1)} />
            <Tab label="Avaliação" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: description || "Nenhuma descrição cadastrada.",
            }}
          ></div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html:
                technicalInformation ||
                "Nenhuma informação técnica encontrada.",
            }}
          ></div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {comments?.map((comment: any) => (
            <Comment
              rating={comment?.rating}
              comment={comment?.message}
              name={comment?.user?.name}
            />
          ))}
        </TabPanel>
      </Box>
    </div>
  );
};

export default TabsInfo;
