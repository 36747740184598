import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

interface IPath {
  data: null | any;
}

const initialState: IPath = {
  data: null,
};

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    saveSale: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearSale: (state) => {
      state.data = null;
    },
  },
});

export const { saveSale, clearSale } = saleSlice.actions;

export const saleSelector = (state: RootState) => state.sale.data;

export default saleSlice.reducer;
