import api from "./api";

export async function getShopCart() {
  try {
    const { data } = await api.get("shop-cart");

    return data;
  } catch (error) {
    return error;
  }
}

export async function createShopCart(data: any) {
  const response = await api.post(`shop-cart`, { data });

  return response.data;
}

export async function addShopCartItem(data: any) {
  const { cartId, ...rest } = data;
  const response = await api.patch(`shop-cart/${cartId}`, { data: rest });

  return response.data;
}

export async function removeShopCart(id: string) {
  const response = await api.delete(`shop-cart/${id}`);

  return response;
}

export async function updateCartItem(data: any) {
  const { cartId, ...rest } = data;

  const response = await api.patch(`shop-cart/${cartId}/edit-product`, {
    data: {
      productId: rest.product._id,
      ...rest,
    },
  });

  return response;
}

export async function removeShopCartItem({ productId, id }: any) {
  const response = await api.patch(`shop-cart/${id}/remove-product`, {
    data: { productId },
  });

  return response.data;
}

export async function finalizeShopCart(id: string) {
  const response = await api.patch(`shop-cart/${id}/finalize`, { data: {} });

  return response.data;
}
