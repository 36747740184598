import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 237px));
  gap: 30px;
  justify-content: center;

  margin-bottom: 60px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const NotFound = styled.h4`
  width: fit-content;
  margin: 0 auto;
  margin-top: -30px;
  font-size: 20px;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 267px;
  padding: 20px;
  background: white;
  border: 1px solid #ececec;
  border-radius: 5px;

  img {
    margin: auto;
    display: block;
    object-fit: cover;
    width: 200px;
    height: 300px;
  }
`;

export const Bottom = styled.div`
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;

  p {
    color: black;
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: regular;
    color: #e64c15;
    text-transform: uppercase;
  }

  p.brand {
    margin-top: 5px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: 500;
    }
  }

  p.price-over-time {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  p.total-price {
    font-weight: 500;
    font-size: 12px;
    margin-top: 3px;

    span {
      text-decoration: line-through;
      font-weight: regular;
      position: relative;
      left: 2px;
      bottom: 2px;
    }
  }

  span.frete {
    font-size: 10px;
    display: inline-block;
    color: #1ea418;
    margin-top: 2px;
  }
`;
