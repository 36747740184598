import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import ViewProduct from "./app/pages/Product";
import Login from "./app/pages/Auth/Login";
import Compra from "./app/pages/Compra";
import Agendamentos from "./app/pages/Agendamentos";
import Home from "./app/pages/Home";
import CadastroUsuario from "./app/pages/Auth/Cadastro";
import CadastroNovoUsuario from "./app/pages/CadastroUsuario";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import "react-credit-cards/es/styles-compiled.css";
import { ToastContainer } from "react-toastify";

import ProtectedRoute from "./app/components/ProtectedRoute";
import Contact from "./app/pages/Contact";
import ConfirmacaoAgendamento from "./app/pages/ConfirmacaoAgendamento";
import Products from "./app/pages/Products";
import Pagamento from "./app/pages/Pagamento";
import PasswordForgot from "./app/pages/Auth/PasswordRecovery";
import PerguntasFrequentes from "./app/pages/PerguntasFrequentes";

const App = () => {
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(() => {});
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={<ProtectedRoute unauthenticated element={<Home />} />}
        />

        <Route path="/login" element={<ProtectedRoute element={<Login />} />} />
        <Route
          path="/cadastro-usuario"
          element={<ProtectedRoute element={<CadastroNovoUsuario />} />}
        />
        <Route
          path="/perfil"
          element={
            <ProtectedRoute authenticated element={<CadastroUsuario />} />
          }
        />
        <Route
          path="/item-produto/:id"
          element={<ProtectedRoute unauthenticated element={<ViewProduct />} />}
        />
        <Route
          path="/compra"
          element={<ProtectedRoute authenticated element={<Compra />} />}
        />
        <Route
          path="/agendamentos"
          element={<ProtectedRoute authenticated element={<Agendamentos />} />}
        />

        <Route
          path="/contato"
          element={<ProtectedRoute unauthenticated element={<Contact />} />}
        />

        <Route
          path="/confirmacaoagendamento"
          element={
            <ProtectedRoute
              unauthenticated
              element={<ConfirmacaoAgendamento />}
            />
          }
        />
        <Route
          path="/produtos"
          element={<ProtectedRoute unauthenticated element={<Products />} />}
        />
        <Route
          path="/pagamento"
          element={<ProtectedRoute authenticated element={<Pagamento />} />}
        />
        <Route
          path="/perguntas-frequentes"
          element={
            <ProtectedRoute unauthenticated element={<PerguntasFrequentes />} />
          }
        />
        <Route
          path="/recuperar-senha"
          element={<ProtectedRoute element={<PasswordForgot />} />}
        />
      </Routes>
    </>
  );
};

export default App;
