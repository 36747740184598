import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  min-height: 40vh;

  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #ececec;

  .top {
    padding: 30px;
    border-bottom: 1px solid #ececec;
    width: 100%;

    h2 {
      font-size: 20px;
      color: #f26722;
      margin: 0 auto;
      width: fit-content;
    }
  }

  .bottom {
    padding: 30px;

    p {
      font-size: 14px;
      color: #999;
      max-width: 500px;
      text-align: center;
      margin: 0px auto 20px;
    }

    > div {
      display: flex;
      gap: 20px;

      @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
        button {
          width: 100%;
        }
      }
    }

    div.code {
      width: 100%;
    }
  }

  img {
    width: 100%;
    height: 300px;
    max-width: 300px;
  }

  textarea {
    height: 150px;
    border-radius: 5px;
    width: 100%;
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    resize: none;
  }

  button {
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 15px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #e64c15;
    outline: none;
    margin-left: auto;
    display: block;
  }

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1160px;

  display: flex;
  gap: 50px;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 1050px) {
    flex-direction: column;
  }
`;

export const Right = styled.div`
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: 1px solid #ececec;
  padding-top: 20px;

  p {
    padding: 0 15px;
    font-size: 16px;
  }

  span {
    font-size: 14px;
    padding: 0 15px;
    color: #999;
  }

  .bottom {
    padding: 20px;
    background: #f7f7f7;

    span {
      font-weight: bold;
      color: black;
      font-size: 20px;
      padding: 0;
    }
  }

  @media (max-width: 1050px) {
    max-width: initial;

    .bottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }
  }
`;
