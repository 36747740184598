import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;


export const Row = styled.section<{marginBottom?: string}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  margin-bottom: ${props => props.marginBottom || 0};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 20px auto;

  min-height: 40vh;

  .empty {
    margin: 50px 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;

  margin: 50px 0;

  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    margin-top: 20px;
  }
`;

export const Left = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const SaleSection = styled.section`
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  > div {
    padding: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #888888;
  }
`;

export const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px !important;

  /* :last-child {
    margin-bottom: 20px;
  } */

  label {
    font-size: 16px;
    font-weight: 500;
    color: #888888;

    display: flex;
    /* align-items: center; */
    gap: 5px;
  }
`;

export const Product = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;

  div.description {
    h3 {
      font-size: 18px;
      color: #e64c15;
    }
    width: 100%;
    flex: 1 1 300px;

    p {
      font-size: 20px;
      margin-top: 10px;
      max-width: 250px;
    }
  }

  div.buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    button {
      width: 100%;
      font-weight: bold;
      height: 45px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      /* margin-left: auto; */
      display: flex;
      align-items: center;
      justify-content: center;

      :first-child {
        color: var(--green);

        border: 2px solid var(--green);
        background: white;
      }

      :last-child {
        color: white;
        background: red;
        border: none;
      }
    }
  }

  img {
    height: 200px;
    width: 150px;
    border-radius: 5px;
    object-fit: cover;
  }

  @media (max-width: 750px) {
    button {
      max-width: initial;
    }
  }
`;

export const Right = styled.div`
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 15px;
  align-self: flex-start;
  width: 100%;
  max-width: 300px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 700;
    border-radius: 5px;
    border: none;
    outline: none;
    width: 100%;
    margin-top: 20px;
    background: #e64c15;
    padding: 15px;

    transition: 0.15s linear;

    :disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  h3 {
    font-size: 20px;
  }

  div.section {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  div.text {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  div.line {
    display: flex;
    width: 100%;
    justify-content: space-between;

    span {
      font-size: 16px;
      color: #aaaaaa;
      font-weight: 500;
    }
  }

  @media (max-width: 900px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;

  border-radius: 50%;

  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid transparent;

  animation: ${spin} infinite 1s linear;
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 30px 40px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  border-radius: 5px;

  h4 {
    margin: 15px 0;
  }

  h3 {
    font-size: 20px;
    width: fit-content;
    margin: 0 auto;
  }

  > div.buttons {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    button {
      height: 40px;
      width: 100%;
      border-radius: 5px;
      border: none;
      outline: none;
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;

      :disabled {
        opacity: 0.7;
      }

      :first-child {
        background: var(--green);
      }

      :last-child {
        background: red;
      }
    }
  }
`;

export const EditModal = styled.div`
  max-width: 348px;

  .qty {
    margin-bottom: -15px;
    margin-top: 30px;
  }
`;
