import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CadastroUsuario from "../../components/Cadastro";
import Breadcrumb from "../../components/breadcrumb";
import { Container, Wrapper } from "./styles";

const CadastroNovoUsuario = () => {
  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <Wrapper>
        <Breadcrumb />

        <Container>
          <CadastroUsuario />
        </Container>

        <Footer />
      </Wrapper>
    </>
  );
};
export default CadastroNovoUsuario;
