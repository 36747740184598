import { FaUserAlt } from "react-icons/fa";
import Rating from "@mui/material/Rating";
import { Container } from "./styles";

interface IProps {
  name?: string;
  comment?: string;
  rating?: number;
}

const Comment: React.FC<IProps> = ({ name, comment, rating }) => {
  return (
    <Container>
      <div className="profile">
        <FaUserAlt />
      </div>
      <div className="right">
        <span className="name">{name || "Usuário desconhecido"}</span>
        <Rating readOnly value={rating || 0} />
        <p>{comment || "Nenhum comentário."}</p>
      </div>
    </Container>
  );
};

export default Comment;
