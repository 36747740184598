import styled from "styled-components";

export const SwiperContainer = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 20px;
  width: 100%;
  max-width: 1160px;

  min-height: 60vh;

  .title {
    width: 100%;
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;

    h3 {
      font-size: 30px;
      font-weight: 600;
      color: #000;
      display: inline-block;
    }

    a {
      font-size: 16px;
      color: #e64c15;
      font-weight: 700;
      text-decoration: underline;
      display: inline-block;
      margin-left: 15px;
    }
  }

  @media (max-width: 750px) {
    margin-top: 30px;
    .title {
      margin-bottom: 30px;
    }
  }
`;
