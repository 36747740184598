import { Table } from "react-bootstrap";
import { Container } from "./styles";

const VehiclesTable: React.FC<{ vehicles: any; removeVehicle: any }> = ({
  vehicles,
  removeVehicle,
}) => {
  return (
    <Container>
      <div className="col-12">
        <div className="title">
          <h3>Veículos cadastrados</h3>
          <hr />
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Placa</th>
            <th>Montadora</th>
            <th>Modelo</th>
            <th>Ano</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {vehicles?.map((vehicle: any) => (
            <tr key={vehicle._id}>
              <td>{vehicle?.plate || ""}</td>
              <td>{vehicle?.brand || ""}</td>
              <td>{vehicle?.model?.name || ""}</td>
              <td>{vehicle?.year || ""}</td>
              <td>
                <button
                  className="remove"
                  onClick={() => removeVehicle(vehicle)}
                >
                  Remover
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default VehiclesTable;
