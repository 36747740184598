import { ReactNode } from "react";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addShopCartItem,
  getShopCart,
  removeShopCartItem,
} from "../../services/shop-cart.service";
import { RootState } from "../index";

export interface ShopCartState {
  [x: string]: ReactNode;
  items: any;
  data: any | null;
  loading: boolean;
}

const initialState: ShopCartState = {
  data: null,
  loading: false,
  items: undefined,
};

export const userShopCart = createAsyncThunk(
  "shopCart/getShopCart",
  async () => {
    // const response = await getShopCart();

    return getShopCart();
  }
);

export const addShopCart = createAsyncThunk(
  "shopCart/createShopCart",
  async (data: any) => {
    const response = await addShopCartItem(data);

    return response;
  }
);

export const removeShopCart = createAsyncThunk(
  "shopCart/removeShopCartItem",
  async (id: string) => {
    const response = await removeShopCartItem(id);

    return response;
  }
);

const shopCartSlice = createSlice({
  name: "shopCart",
  initialState,
  reducers: {
    saveShopCart: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },

    clearShopCart: (state: ShopCartState) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userShopCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(userShopCart.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
  },
});

export const { saveShopCart, clearShopCart } = shopCartSlice.actions;

export const shopCartSelector = (state: RootState) => state.shopCart.data;
export const shopCartLoadingSelector = (state: RootState) =>
  state.shopCart.loading;

export default shopCartSlice.reducer;
