import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { handleToast } from "../../utils/handleToast";

import { Container } from "./styles";

interface IProps {
  counter: number;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  amount: number;
}

const Counter: React.FC<IProps> = ({ counter, setCounter, amount }) => {
  const onValueChange = (value: string | number) => {
    if (Number(value) > amount) {
      setCounter(Number(amount));
      return handleToast(true, `Quantidade não pode ser superior a ${amount}`);
    }

    if (parseInt(value.toString()) < 1) {
      return setCounter(1);
    }

    setCounter(Number(value));
  };

  return (
    <Container>
      <button
        className="bt-count"
        onClick={() => {
          onValueChange(counter - 1);
        }}
        disabled={counter <= 1}
      >
        {" "}
        <RemoveIcon fontSize="medium" />
      </button>

      <input
        value={counter}
        onChange={(e) => onValueChange(e.target.value)}
        type="number"
      />

      <button
        className="bt-count"
        onClick={() => {
          onValueChange(counter + 1);
        }}
        disabled={counter === amount}
      >
        {" "}
        <AddIcon fontSize="medium" />
      </button>
    </Container>
  );
};
export default Counter;
