import { FaClock } from "react-icons/fa";
import { Container } from "./styles";

interface IProps {
  time: number;
  setTime:
    | React.Dispatch<React.SetStateAction<number>>
    | ((value: number) => void);
  possibleTimes: number[];
  loading?: boolean;
}

const Time: React.FC<IProps> = ({ time, setTime, possibleTimes, loading }) => {
  if (loading)
    return (
      <strong
        style={{
          margin: "10px auto 0",
          display: "block",
          width: "fit-content",
        }}
      >
        Buscando horários
      </strong>
    );

  if (!loading && !possibleTimes?.length)
    return (
      <strong
        style={{
          margin: "10px auto 0",
          display: "block",
          width: "fit-content",
        }}
      >
        Nenhum horário disponível
      </strong>
    );

  return (
    <Container>
      <span>Selecione um horário</span>
      <div>
        <span className="icon">
          <FaClock />
        </span>
        {!loading && !!possibleTimes?.length && (
          <select
            value={time}
            onChange={({ target }) => setTime(Number(target.value))}
          >
            <option value={0} hidden>
              Selecionar
            </option>
            {possibleTimes
              ?.slice(0)
              ?.reverse()
              ?.map((item) => (
                <option key={item} value={item}>
                  {item}:00
                </option>
              ))}
          </select>
        )}
      </div>
    </Container>
  );
};

export default Time;
