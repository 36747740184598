import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import {
  FaFacebookSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaTelegram,
  FaTiktok,
  FaInstagramSquare,
} from "react-icons/fa";

import Logofooter from "../../img/logo-branca.png";
import api from "../../services/api";
import { Button, ModalContainer, ModalCustom } from "./styles";

interface IProps {
  loadingPage?: boolean;
}

const Footer: React.FC<IProps> = ({ loadingPage }) => {
  const [data, setData] = useState({
    termsOfUse: { html: "" },
    privacyPolicy: { html: "" },
  });

  const [modalOpen, setModalOpen] = useState({
    termsOfUse: false,
    privacy: false,
  });

  const [socialMedia, setSocialMedia] = useState<any>([]);

  useEffect(() => {
    if (!loadingPage) {
      const fetch = async () => {
        const systemResponse = await api.get(`/system`);
        const socialMediaResponse = await api.get(`/social-media-list`);

        setData(systemResponse?.data?.data?.items[0]);

        setSocialMedia(socialMediaResponse?.data?.data);
      };

      fetch();
    }
  }, [loadingPage]);

  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-12 col-sm-4">
              <div className="box">
                <Link to="/">
                  <img src={Logofooter} alt="lubbri" className="img-fluid" />
                </Link>
                <p className="text-footer">
                  Proin eu posuere quam, suscipit molestie nisl. Nullam
                  tincidunt pulvinar metus, at vestibulum ex sagittis ut.
                  Vivamus sit amet ex vitae nisl imperdiet convallis ac
                </p>

                <ul className="social">
                  {socialMedia.map((item: any) => {
                    let socialMedia = null;

                    if (item.socialName === "FACEBOOK")
                      socialMedia = <FaFacebookSquare />;
                    if (item.socialName === "INSTAGRAM")
                      socialMedia = <FaInstagramSquare />;
                    if (item.socialName === "TIKTOK")
                      socialMedia = <FaTiktok />;
                    if (item.socialName === "TWITTER")
                      socialMedia = <FaTwitterSquare />;
                    if (item.socialName === "LINKEDIN")
                      socialMedia = <FaLinkedin />;
                    if (item.socialName === "TELEGRAM")
                      socialMedia = <FaTelegram />;

                    if (socialMedia) {
                      return (
                        <a
                          href={
                            item.socialLink.includes("http://") ||
                            item.socialLink.includes("https://")
                              ? item.socialLink
                              : "https://" + item.socialLink
                          }
                          target="_blank"
                          rel="noreferrer noopener"
                          style={{ color: "white" }}
                          key={item.socialName}
                        >
                          {socialMedia}
                        </a>
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <div className="box">
                <h4>Navegação</h4>

                <ul style={{ paddingTop: "20px" }}>
                  <li>
                    <Link to="/cadastro-usuario">Cadastro</Link>
                  </li>
                  <li>
                    <Link to="/contato">Fale Conosco</Link>
                  </li>
                  <li>
                    <a
                      href="https://seller-lubbri.omegacotech.com.br/cadastro"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Venda Seu Produto
                    </a>
                  </li>
                  <li>
                    <Link to="/">Duvidas Frequentes</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container copy">
          <div className="row">
            <div className="col">
              <Button
                onClick={() => setModalOpen({ ...modalOpen, termsOfUse: true })}
              >
                Termos de Uso
              </Button>
              |
              <Button
                onClick={() => setModalOpen({ ...modalOpen, privacy: true })}
              >
                Políticas de privacidade
              </Button>
              | Anúncios Baseados em Interesses | &copy;
              {new Date().getFullYear()} Lubbri Car Service
            </div>
          </div>
        </div>
      </footer>
      <ModalCustom
        open={modalOpen.termsOfUse}
        onClose={() => setModalOpen({ ...modalOpen, termsOfUse: false })}
      >
        <ModalContainer>
          <header>
            <h3>Termos de uso</h3>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.termsOfUse.html || "Não encontrado.",
            }}
          />
        </ModalContainer>
      </ModalCustom>
      <ModalCustom
        open={modalOpen.privacy}
        onClose={() => setModalOpen({ ...modalOpen, privacy: false })}
      >
        <ModalContainer>
          <header>
            <h3>Políticas de Privacidade</h3>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.privacyPolicy?.html || "Não encontrado.",
            }}
          />
        </ModalContainer>
      </ModalCustom>
    </>
  );
};

export default Footer;
