import { useState, useCallback } from "react";
import { Formik } from "formik";

import { Container, Form, Input, Spinner, Textarea, Wrapper } from "./styles";

import Header from "../../components/Header";
import Breadcrumb from "../../components/breadcrumb";
import Footer from "../../components/Footer";

import * as yup from "yup";
import api from "../../services/api";
import { handleToast } from "../../utils/handleToast";

const validationSchema = yup.object().shape({
  name: yup.string().required("Entre com um nome"),
  email: yup
    .string()
    .email("Entre com um e-mail válido")
    .required("Entre com um e-mail válido"),
  phone: yup.string().required("Entre com um telefone válido"),
  message: yup
    .string()
    .required("Entre com a mensagem")
    .min(5, "Mensagem deve ter no mínimo 5 caracteres")
    .max(400, "Mensagem deve ter no máximo 400 caracteres"),
});
const cellPhoneMask = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (data: any) => {
    setLoading(true);
    await api.post("/contact", {
      data: {
        name: data.name,
        telefone: data.phone,
        email: data.email,
        message: data.message,
      },
    });

    handleToast(false, "Mensagem enviada.");
    setLoading(false);
  }, []);

  return (
    <Wrapper className='wrapper'>
      <Header />
      <Breadcrumb />
      <Container>
        <h1>Fale conosco</h1>
        <p>
          O que você tem a dizer é muito importante para nós. Para que possamos
          melhor atendê-lo, preencha os campos abaixo corretamente e deixe sua
          dúvida ou sugestão.
        </p>

        <Formik
          initialValues={{ name: "", phone: "", message: "", email: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <div>
              <div className="input-container">
                <label>Nome:</label>
                <Input placeholder="Nome" name="name" />
              </div>
              <div className="input-container">
                <label>E-mail:</label>
                <Input name="email" placeholder="E-mail" />
              </div>
              <div className="input-container">
                <label>Telefone:</label>
                <Input
                  name="phone"
                  type="tel"
                  placeholder="(99) 9999-9999"
                  mask={cellPhoneMask}
                />
              </div>
            </div>
            <div>
              <div className="input-container">
                <label>Mensagem:</label>
                <Textarea name="message" placeholder='Escrava aqui sua mensagem para nós' />
              </div>
            </div>
            <button type="submit" disabled={loading}>
              {loading ? <Spinner /> : "Enviar"}
            </button>
          </Form>
        </Formik>
      </Container>
      <Footer />
    </Wrapper>
  );
};

export default Contact;
