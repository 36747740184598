import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  h2 {
    font-size: 30px;
    margin: 20px auto;
    font-weight: 700;
    margin: 0 auto;
    width: fit-content;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

export const Container = styled.div`
  margin-top: 30px;

  width: 100%;
  max-width: 1160px;

  margin: 30px auto;

  min-height: 40vh;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 267px));
  gap: 30px;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 267px;
  padding: 20px;
  background: white;
  border: 1px solid #ececec;
  border-radius: 5px;

  img {
    margin: auto;
    display: block;
    object-fit: cover;
    width: 200px;
    height: 300px;
  }
`;

export const Bottom = styled.div`
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;

  p {
    color: black;
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: regular;
    color: #e64c15;
    text-transform: uppercase;
  }

  p.brand {
    margin-top: 5px;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: 500;
    }
  }

  p.price-over-time {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  p.total-price {
    font-weight: 500;
    font-size: 12px;
    margin-top: 3px;

    span {
      text-decoration: line-through;
      font-weight: regular;
      position: relative;
      left: 2px;
      bottom: 2px;
    }
  }

  span.frete {
    font-size: 10px;
    display: inline-block;
    color: #1ea418;
    margin-top: 2px;
  }
`;

export const PaginationContainer = styled.div`
  margin: 30px auto;
  display: flex;
  justify-content: center;

  button {
    background: var(--green) !important;
    color: white;
    border: 0;
  }

  .Mui-selected {
    border: 1px solid var(--green);
    color: var(--green);
    background: white !important;
  }
`;
