import { useState, useEffect, useCallback } from "react";
import { TbMapPin } from "react-icons/tb";
import { Link } from "react-router-dom";
import cep from "cep-promise";
import MaskedInput, { conformToMask } from "react-text-mask";

import { handleToast } from "../../../utils/handleToast";
import {
  AddressOption,
  AddressPickerContainer,
  Button,
  Buttons,
  CEPContainer,
  ModalCustom,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { clearAddress, IAddress, saveAddress } from "../../../store/address";

const Address: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const address = useAppSelector((state) => state.address.data);

  const [modal, setModal] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<any>(address || null);
  const [loading, setLoading] = useState(true);
  const [currentCEP, setCurrentCEP] = useState("");
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [tempAddress, setTempAddress] = useState<IAddress | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    if (modal) {
      setCurrentAddress(address);
    }
    setLoading(false);
  }, [address, modal]);

  useEffect(() => {
    if (!modal) {
      if (currentAddress) {
        setCurrentAddress(null);
      }
      if (tempAddress) {
        setCurrentAddress(null);
      }

      if (currentCEP) {
        setCurrentCEP("");
      }
    }
  }, [currentAddress, currentCEP, modal, tempAddress]);

  useEffect(() => {
    if (user?.data?.user?._id !== address?.user) {
      setCurrentAddress(null);
      setTempAddress(null);
      dispatch(clearAddress());
    }
  }, [address?.user, dispatch, user]);

  useEffect(() => {
    if (currentAddress?.street && currentAddress?._id) {
      setTempAddress(null);
    }
  }, [currentAddress]);

  useEffect(() => {
    if (address?.street && !address._id) {
      setTempAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (user?.data?.user?.address?.length && !address) {
      dispatch(
        saveAddress({
          ...user?.data?.user?.address[0],
          user: user?.data?.user?._id,
        })
      );
    }
  }, [address, dispatch, user]);

  const onSearchCEP = useCallback(async () => {
    setLoadingCEP(true);

    try {
      setTempAddress(null);
      const res = await cep(currentCEP);
      setTempAddress({ ...res, district: res.neighborhood });
    } catch (error: any) {
      error?.errors?.forEach((e: any) => handleToast(true, e?.message));
    } finally {
      setLoadingCEP(false);
    }
  }, [currentCEP]);

  const onSaveAddress = useCallback(() => {
    if (currentAddress) {
      dispatch(saveAddress({ ...currentAddress, user: user?.data?.user?._id }));
    }

    if (tempAddress) {
      dispatch(saveAddress(tempAddress));
    }

    handleToast(false, "Endereço alterado.");

    setModal(false);
  }, [currentAddress, dispatch, tempAddress, user?.data?.user?._id]);

  if (loading) return null;

  return (
    <div style={{ width: "100%", maxWidth: "1160px" }}>
      <Button onClick={() => setModal(true)}>
        <div>
          <TbMapPin size={20} />
        </div>
        {address ? (
          <div>
            <p>Enviar para</p>
            {address.street && (
              <p>
                {address?.street}, {address?.district}, {address?.city} -{" "}
                {address?.state}
              </p>
            )}
          </div>
        ) : (
          <div>
            <p>Escolher endereço</p>
          </div>
        )}
      </Button>
      <ModalCustom open={modal} onClose={() => setModal(false)}>
        <AddressPickerContainer>
          <h2>Escolha um endereço</h2>

          <div>
            <h3>Endereços cadastrados</h3>

            {user?.data?.user?.address?.map((address: any) => (
              <AddressOption
                selected={currentAddress?._id === address?._id}
                onClick={() =>
                  setCurrentAddress({
                    ...address,
                    user: user?.data?.user?._id ?? null,
                  })
                }
                key={address?._id}
              >
                <div className="radio" />
                <div>
                  <p>
                    {
                      conformToMask(address?.zipcode, [
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                      ]).conformedValue
                    }
                  </p>
                  <p>
                    {address?.street}
                    {address?.number ? `, ${address?.number}, ` : ""}
                    {address?.complement ? `, ${address?.complement}, ` : ""}
                    {address?.district}, {address?.city} - {address?.state}
                  </p>
                </div>
              </AddressOption>
            ))}
          </div>
          <div>
            <h3>Outro endereço</h3>
            <CEPContainer>
              <div className="input-container">
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                  placeholder="Informe um CEP"
                  value={currentCEP}
                  name="cep"
                  guide={false}
                  onChange={(e) => setCurrentCEP(e.target.value)}
                />
                <button onClick={onSearchCEP} disabled={loadingCEP}>
                  Usar
                </button>
              </div>
              {tempAddress && !!tempAddress.street && (
                <div className="address-content">
                  {tempAddress.street}, {tempAddress.district},{" "}
                  {tempAddress.city} - {tempAddress.state}
                </div>
              )}
            </CEPContainer>
          </div>
          {user?.data?.user?._id ? (
            <Link to="/perfil">Cadastrar novo endereço</Link>
          ) : (
            <Link to="/cadastro-usuario">Cadastrar novo endereço</Link>
          )}
          <Buttons>
            <button onClick={() => setModal(false)}>Cancelar</button>
            <button onClick={onSaveAddress} disabled={loadingCEP}>
              Salvar
            </button>
          </Buttons>
        </AddressPickerContainer>
      </ModalCustom>
    </div>
  );
};

export default Address;
