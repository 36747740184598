import styled from "styled-components";

export const Container = styled.div`
  .buttons {
    margin-top: 20px;
    margin-left: auto;
    width: fit-content;
    display: flex;
    gap: 5px;
  }

  .swiper-pagination {
    bottom: 0 !important;
    margin-top: 10px;
    position: static;
  }

  .swiper-nav {
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #1ea418;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    transition: 0.15s linear;
  }

  .swiper-nav-active {
    width: 20px;
    height: 20px;
    background: #1ea418;
    border: 1px solid white;
    border-radius: 50%;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    transition: 0.1s linear;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
  }
`;
