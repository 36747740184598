import { Modal } from "@mui/material";
import styled from "styled-components";

export const Button = styled.button`
  display: inline;
  color: white;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 850px;
  padding: 30px;
  background: white;
  max-height: 90vh;
  overflow-y: auto;
  outline: none;
  border: none;

  border-radius: 5px;

  div {
    overflow: auto;
  }

  header {
    padding: 10px 0;
    background: white;
  }
`;

export const ModalCustom = styled(Modal)`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
`;
