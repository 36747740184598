import Vehicles from "../../Vehicles";
import { Buttons, Form, Input, InputContainer } from "../styles";
import { FieldArray } from "formik";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const FormikForm: React.FC<any> = ({
  vehicles,
  setVehicles,
  loading,
  values,
}) => {
  const navigate = useNavigate();

  return (
    <Form>
      <div className="title">
        <h3>Dados pessoais</h3>
        <hr />
      </div>
      <div className="form-row">
        <InputContainer>
          <label>Nome</label>
          <Input name="name" placeholder="Nome" />
        </InputContainer>
        <InputContainer>
          <label>E-mail</label>
          <Input name="email" placeholder="E-mail" type="email" />
        </InputContainer>
      </div>
      <FieldArray
        name="address"
        render={({ push, remove }) => {
          return (
            <>
              {values?.address?.map((value: any, index: number) => (
                <>
                  <div className="form-row">
                    <h3>Endereço {index + 1}</h3>
                    {values?.address?.length > 1 && (
                      <button
                        className="remove"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <MdDelete size={24} />
                      </button>
                    )}
                  </div>
                  <div className="form-row">
                    <InputContainer>
                      <label>CEP</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.cep`}
                        type="tel"
                        placeholder="CEP"
                      />
                    </InputContainer>
                    <InputContainer>
                      <label>Estado</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.state`}
                        type="text"
                        placeholder="Estado"
                      />
                    </InputContainer>
                    <InputContainer>
                      <label>Cidade</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.city`}
                        type="text"
                        placeholder="Cidade"
                      />
                    </InputContainer>
                  </div>
                  <div className="form-row">
                    <InputContainer>
                      <label>Bairro</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.district`}
                        placeholder="Bairro"
                      />
                    </InputContainer>
                    <InputContainer>
                      <label>Rua</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.rua`}
                        placeholder="Rua"
                      />
                    </InputContainer>
                    <InputContainer>
                      <label>Número</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.numero`}
                        type="tel"
                        placeholder="Número"
                      />
                    </InputContainer>
                    <InputContainer>
                      <label>Complemento</label>
                      <Input
                        disabled={loading}
                        name={`address.${index}.complemento`}
                        placeholder="Complemento"
                      />
                    </InputContainer>
                  </div>
                </>
              ))}
              <button
                className="add"
                type="button"
                onClick={() =>
                  push({
                    rua: "",
                    cep: "",
                    numero: "",
                    complemento: "",
                    district: "",
                    state: "",
                    city: "",
                  })
                }
              >
                Adicionar endereço
              </button>
            </>
          );
        }}
      />
      <Vehicles vehicles={vehicles} setVehicles={setVehicles} />
      <Buttons>
        <button type="button" onClick={() => navigate(-1)}>
          Cancelar
        </button>
        <button type="submit" disabled={loading}>
          Confirmar
        </button>
      </Buttons>
    </Form>
  );
};

export default FormikForm;
