// import AsyncStorage from '@react-native-async-storage/async-storage'
import { combineReducers } from "redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import token from "./token";
import products from "./products";
import user from "./user";
import shopCart from "./shop-cart";
import address from "./address";
import redirect from "./redirect";
import sale from "./sale";

const reducers = combineReducers({
  token,
  products,
  user,
  shopCart,
  address,
  redirect,
  sale,
});

type PersistConfigType = {
  key: string;
  storage: any;
  blacklist: Array<string>;
};

const persistConfig: PersistConfigType = {
  key: "root",
  storage: storage,
  // whitelist: ['token', 'user'],
  blacklist: ["redirect", "products", "shopCart", "sale"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    return middlewares;
  },
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export { store, persistor };
