import { useCallback, useState } from "react";

import { Link } from "react-router-dom";
import { Formik } from "formik";

import {
  Input,
  InputContainer,
  Form,
  Buttons,
  Spinner,
  FlexContainer,
} from "./styles";
import REcaptcha from "react-google-recaptcha";

import { loginToken } from "../../store/token";
import { useAppDispatch } from "../../hooks";
import { handleToast } from "../../utils/handleToast";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface ISignIn {
  email: string;
  password: string;
}

interface IProps {
  prevRoute?: string;
}

const Formulario = ({ prevRoute }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<boolean>(false);
  const [seePassword, setSeePassword] = useState<boolean>(false);

  const login = useCallback(
    async (email: string, password: string) => {
      const response = await dispatch(loginToken({ email, password }));
      return response.payload;
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    async ({ email, password }: ISignIn) => {
      try {
        setLoading(true);
        const response = await login(email, password);

        if (!response.data) {
          setLoading(false);
          return handleToast(true, response.message);
        }
      } catch (e) {
        setLoading(false);
        return handleToast(true, "Erro ao tentar logar");
      } finally {
        setLoading(false);
      }
    },
    [login]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ email: "", password: "" } as any}
    >
      <Form>
        <div className="form-row">
          <InputContainer>
            <label>E-mail</label>
            <Input type="email" placeholder="E-mail" name="email" />
          </InputContainer>
        </div>
        <div className="form-row">
          <InputContainer>
            <InputContainer>
              <label>Senha</label>
              <FlexContainer>
                <Input
                  type={seePassword ? "text" : "password"}
                  placeholder="Senha"
                  name="password"
                />
                <IconButton
                  onClick={() => setSeePassword(!seePassword)}
                  edge="end"
                  aria-label={seePassword ? "Esconder senha" : "Mostrar senha"}
                >
                  {seePassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </FlexContainer>
            </InputContainer>
          </InputContainer>
        </div>
        <Link to="/recuperar-senha">Esqueci a senha</Link>
        <REcaptcha
          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""}
          onChange={() => setChecked(true)}
        />

        <Buttons>
          <button type="submit" disabled={loading || !checked}>
            {loading ? <Spinner /> : "Entrar"}
          </button>
          <Link to="/cadastro-usuario">Cadastrar</Link>
        </Buttons>
      </Form>
    </Formik>
  );
};
export default Formulario;
