import styled from "styled-components";

export const Column = styled.section`
  display: flex;
  flex-direction: column;
`;

export const AddressWrapper = styled.section`
  margin-bottom: 20px;

  input {
    padding: 6px 12px;
    border: 1px solid #ececec;
    outline: none;
    display: block;
    width: 420px;
    border-radius: 5px;
    margin-top: 10px;

    transition: 0.15s linear;

    ::placeholder {
      color: #bababa;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bababa;
    }

    ::-ms-input-placeholder {
      color: #bababa;
    }
  }

  > div {
    display: flex;
    gap: 10px;
    width: 400px;
  }
`;

export const Wrapper = styled.div`
  margin: 0;
  padding: 0 20px !important;
  margin-bottom: 20px;

  h3 {
    font-size: 20px;
    margin-top: 20px;
  }

  > div.address {
    input {
      padding: 6px 12px;
      border: 1px solid #ececec;
      outline: none;
      display: block;
      width: 420px;
      border-radius: 5px;
      margin-top: 10px;

      transition: 0.15s linear;

      ::placeholder {
        color: #bababa;
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: #bababa;
      }

      ::-ms-input-placeholder {
        color: #bababa;
      }
    }

    > div {
      display: flex;
      gap: 10px;
      width: 400px;
    }
  }
`;

export const PixContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  /* margin-bottom: 30px; */

  input {
    padding: 6px 12px;
    border: 1px solid #ececec;
    outline: none;
    display: block;
    width: 200px;
    border-radius: 5px;
    margin-top: 10px;

    transition: 0.15s linear;

    ::placeholder {
      color: #bababa;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bababa;
    }

    ::-ms-input-placeholder {
      color: #bababa;
    }
  }
`;

export const CreditCardContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;

  > div {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .form {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  input,
  select {
    padding: 6px 12px;
    border: 1px solid #ececec;
    outline: none;
    display: block;
    width: 205px;
    border-radius: 5px;
    height: 40px;

    transition: 0.15s linear;

    ::placeholder {
      color: #bababa;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #bababa;
    }

    ::-ms-input-placeholder {
      color: #bababa;
    }
  }
`;
