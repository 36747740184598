import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px 0;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: center;

  @media (max-width: 1160px) {
    padding: 20px;
  }

  a {
    color: black;
    transition: 0.15s linear;

    :hover {
      color: #006edc;
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1160px;
`;
