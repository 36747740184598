import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import api from "../../services/api";
import REcaptcha from "react-google-recaptcha";

import {
  Buttons,
  Form,
  Input,
  InputContainer,
  ModalContainer,
  ModalCustom,
  Spinner,
} from "./styles";
import removeAllNonNumberCharacters from "../../utils/removeAllNonNumberCharacters";
import { handleToast } from "../../utils/handleToast";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useCallback } from "react";
import { PasswordVisibilityState } from "./types";
import { FlexContainer } from "../Login/styles";

const CadastroUsuario = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [, setChecked] = useState<boolean>(false);
  const [seePassword, setSeePassword] = useState<PasswordVisibilityState>({
    pass: false,
    confirmPass: false,
  });

  const [data, setData] = useState({
    termsOfUse: { html: "" },
    privacyPolicy: { html: "" },
    LGPD: { html: "" },
  });

  const [modalOpen, setModalOpen] = useState({
    termsOfUse: false,
    privacy: false,
    LGPD: false,
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await api.get(`/system`);
      setData(response.data.data.items[0]);
      setLoading(false);
    };

    fetch();
  }, []);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Entre com um nome"),
    email: yup
      .string()
      .required("Entre com um e-mail")
      .email("Entre com um e-mail válido"),

    password: yup
      .string()
      .min(8, "Senha deve ter no mínimo 8 caracteres")
      .max(32, "Senha deve ter no máximo 32 caracteres")
      .required("Entre com uma senha")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#\\$%\\^&\\*])(?=.{8,})/,
        "Senha deve conter um caracter maiúsculo, um mínusculo, um número e um caractere especial."
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas devem ser iguais"),
    cel: yup
      .string()
      .required("Entre com um celular")
      .test(
        "testCel",
        "Entre com um celular válido",
        (value) =>
          !!removeAllNonNumberCharacters(value || "")?.match(
            /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/
          )
      ),
  });

  const togglePassword = useCallback((field: keyof PasswordVisibilityState) => {
    setSeePassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  }, []);

  const onSubmit = async (formData: any) => {
    setLoading(true);
    const { name, email, password } = formData;

    const unmaskedCel = removeAllNonNumberCharacters(formData.cel);

    const celDDD = unmaskedCel.slice(0, 2);
    const cel = unmaskedCel.slice(2);

    const data = {
      name,
      email,
      password,
      cel,
      celDDD,
      userType: "user",
      termsOfUse: true,
      lgpd: true,
      privacyPolicy: true,
    };

    try {
      const res = await api.post("user/create", data);

      if (!res?.data?.data) {
        setLoading(false);
        return handleToast(true, res?.data?.message);
      }
    } catch (e: any) {
      setLoading(false);
      return handleToast(true, e?.data?.message || "Ocorreu um erro");
    } finally {
      setLoading(false);
    }

    navigate("/login");
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          name: "",
          password: "",
          passwordConfirm: "",
          mobile: "",
          termsOfUse: false,
          LGPD: false,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <div className="form-row">
              <InputContainer>
                <label>Nome</label>
                <Input name="name" placeholder="Nome" type="text" />
              </InputContainer>
              <InputContainer>
                <label>E-mail</label>
                <Input name="email" placeholder="E-mail" type="email" />
              </InputContainer>
            </div>
            <div className="form-row">
              <InputContainer>
                <label>Senha</label>

                <FlexContainer>
                  <Input
                    name="password"
                    placeholder="Senha"
                    type={seePassword.pass ? "text" : "password"}
                  />
                  <IconButton
                    onClick={() => togglePassword("pass")}
                    edge="end"
                    aria-label={
                      seePassword.pass ? "Esconder senha" : "Mostrar senha"
                    }
                  >
                    {seePassword.pass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </FlexContainer>
              </InputContainer>
              <InputContainer>
                <label>Confirme a senha</label>
                <FlexContainer>
                  <Input
                    name="passwordConfirm"
                    placeholder="Confirme a senha"
                    type={seePassword.confirmPass ? "text" : "password"}
                  />
                  <IconButton
                    onClick={() => togglePassword("confirmPass")}
                    edge="end"
                    aria-label={
                      seePassword.confirmPass
                        ? "Esconder senha"
                        : "Mostrar senha"
                    }
                  >
                    {seePassword.confirmPass ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </FlexContainer>
              </InputContainer>
            </div>
            <div className="form-row cell">
              <InputContainer>
                <label>Celular</label>
                <Input
                  name="cel"
                  placeholder="(55) 55555-5555"
                  type="tel"
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </InputContainer>
            </div>
            <REcaptcha
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""}
              onChange={() => setChecked(true)}
              style={{ marginTop: "20px" }}
            />
            <Buttons>
              <button type="submit" disabled={loading}>
                {loading ? <Spinner /> : "Cadastrar"}
              </button>
              <p>Se cadastrando, você concordará com os termos de uso e LGPD</p>
            </Buttons>
          </Form>
        )}
      </Formik>
      <ModalCustom
        open={modalOpen.termsOfUse}
        onClose={() => setModalOpen({ ...modalOpen, termsOfUse: false })}
      >
        <ModalContainer>
          <header>
            <h3>Termos de uso</h3>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.termsOfUse.html || "Não encontrado.",
            }}
          />
        </ModalContainer>
      </ModalCustom>
      <ModalCustom
        open={modalOpen.privacy}
        onClose={() => setModalOpen({ ...modalOpen, privacy: false })}
      >
        <ModalContainer>
          <header>
            <h3>Políticas de Privacidade</h3>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.privacyPolicy?.html || "Não encontrado.",
            }}
          />
        </ModalContainer>
      </ModalCustom>
      <ModalCustom
        open={modalOpen.LGPD}
        onClose={() => setModalOpen({ ...modalOpen, LGPD: false })}
      >
        <ModalContainer>
          <header>
            <h3>Políticas de Privacidade</h3>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.LGPD?.html || "Não encontrado.",
            }}
          />
        </ModalContainer>
      </ModalCustom>
    </>
  );
};
export default CadastroUsuario;
