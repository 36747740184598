import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import { countBy } from "lodash";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Produto from "../../components/product/ProductsSwiper";
import Counter from "../../components/Counter";
import TabsInfo from "../../components/tabsinfo";
import Breadcrumb from "../../components/breadcrumb";
import { useDebounce } from "use-debounce";
import api from "../../services/api";

import NoImage from "../../img/no-image-lg.png";

import "react-calendar/dist/Calendar.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { formatPrice } from "../../utils/formatPrice";
import {
  Container,
  Left,
  ProductData,
  ProductInfo,
  Wrapper,
  Right,
  Row,
  Spinner,
  ImageComponent,
  ImageButtonWrapper,
} from "./styles";
import Time from "../../components/ViewProduct/Time";
import { addShopCart, userShopCart } from "../../store/shop-cart";
import { createShopCart } from "../../services/shop-cart.service";
import { userSelector } from "../../store/user";
import { handleToast } from "../../utils/handleToast";
import LoadingPage from "../../components/LoadingPage";
import { IProduct } from "../../store/products";
import { addressSelector } from "../../store/address";
import { format, subHours } from "date-fns";
import { tokenSelector } from "../../store/token";
import { saveSale } from "../../store/sale";

const ViewProduct: React.FC<any> = () => {
  const location: any = useLocation();

  const [calendarValue, setCalendarValue] = useState<Date>();
  const [time, setTime] = useState<number>(0);
  const [counter, setCounter] = useState<number>(
    location?.state?.cartId?.amount || 1
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [availableTime, setAvailableTime] = useState<number[]>([]);
  const [loadingTimes, setLoadingTimes] = useState<boolean>(false);
  const [distance, setDistance] = useState<number | null>(null);

  const [debouncedCalendar] = useDebounce(calendarValue, 1000);
  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const { shopCart } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const token = useAppSelector(tokenSelector);
  const address = useAppSelector(addressSelector);

  const [comments, setComments] = useState([]);

  const getProduct = async (p: string) => {
    const response = await api.get(`/products-sale/${p}`);

    return response?.data?.data?.item || null;
  };

  const [selectedImage, setSelectedImage] = useState(
    currentProduct?.images?.length ? currentProduct?.images[0] : null
  );

  useEffect(() => {
    if (currentProduct?.images?.length && !selectedImage) {
      setSelectedImage(currentProduct?.images[0]);
    }
  }, [selectedImage, currentProduct?.images]);

  const organizationAddress = () => {
    if (
      !currentProduct?.organization?.address ||
      !currentProduct?.organization?.address[0]
    )
      return "";

    const { street, complement, number, district } =
      currentProduct?.organization.address[0];

    const validFields = [street, complement, number, district].filter(
      (item) => item
    );

    return validFields.join(", ");
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const res = await api.get(`/products-sale/${id}`);
        dispatch(userShopCart);

        setCurrentProduct(res?.data?.data?.item || null);
      } catch (e) {
        handleToast(true, "Ocorreu um erro ao trazer produto");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [dispatch, id]);

  useEffect(() => {
    if (currentProduct?.organization?._id && !loading) {
      const fetch = async () => {
        let latitude, longitude;

        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
          });
        }

        const res = await api.get(
          `location/product-seller/${currentProduct?._id}`,
          {
            params: {
              cep: address?.zipcode || address?.cep,
              latitude,
              longitude,
            },
          }
        );

        if (res?.data?.data?.item?.length && res?.data?.data?.item[0]) {
          const d = res?.data?.data?.item[0].split(" (")[0];
          setDistance(Number((Number(d) / 1000).toFixed(2)));
        }
      };

      fetch();
    }
  }, [currentProduct, loading, address?.cep, address?.zipcode]);

  useEffect(() => {
    const fetch = async () => {
      if (currentProduct?._id) {
        const response = await api.get(
          `products-sale-rating/${currentProduct._id}`
        );

        setComments(response?.data?.items);
      }
    };

    fetch();
  }, [currentProduct?._id]);

  const checkAvailability = React.useCallback(
    async (day: Date) => {
      try {
        setLoadingTimes(true);
        const res = await api.get(
          `/organization-settings/${currentProduct?.organization._id}/logout`,
          {
            params: { startDate: day },
          }
        );

        const dayOfWeek = format(day, "EEEE").toLowerCase();

        const openDay = res?.data?.data?.settings?.open?.find(
          (item: any) => item.dayOfWeek === dayOfWeek
        );

        const availableDayTime = {
          start: Number(openDay.openClock.split(":")[0]),
          finish: Number(openDay.closeClock.split(":")[0]),
        };

        if (!res?.data?.data?.items?.length) {
          const tempAvailableTime = Array.from(
            { length: availableDayTime.finish - availableDayTime.start + 1 },
            (_, index) => index + availableDayTime.start
          );

          setAvailableTime(tempAvailableTime.filter((item) => item));

          return setLoadingTimes(false);
        }

        const grouped = countBy(res?.data?.data?.items, "reservedHour");

        const blockedHours: any = [];

        for (const key in grouped) {
          if (
            grouped[key] >= res?.data?.data?.settings?.simultaneousAppointments
          ) {
            blockedHours.push(key);
          }
        }

        const tempAvailableTime = Array.from(
          { length: availableDayTime.finish - availableDayTime.start },
          (_, index) => index + availableDayTime.start
        ).filter((item) => {
          return !blockedHours.find(
            (i: any) => Number(i.split(":")[0]) === item
          );
        });

        setAvailableTime(tempAvailableTime.filter((item) => item));

        setLoadingTimes(false);
      } catch (error) {
        setLoadingTimes(false);
      }
    },
    [currentProduct?.organization._id]
  );

  useEffect(() => {
    if (currentProduct?.amount === 0) {
      setCounter(0);
    }
  }, [currentProduct]);

  const onSubmit = async () => {
    if (!calendarValue) {
      return handleToast(true, "Escolha uma data");
    }

    setLoading(true);
    const date = calendarValue as Date;

    if (shopCart?.data?.items && shopCart?.data?.items?.products[0]) {
      const cartProduct = await getProduct(
        shopCart?.data?.items?.products[0]?.saleProducts
      );

      if (
        cartProduct &&
        cartProduct.organization?._id &&
        cartProduct.organization._id !== currentProduct?.organization?._id
      ) {
        setLoading(false);
        return handleToast(
          true,
          "Carrinho deve ser composto por itens do mesmo local."
        );
      }
    }

    const timeZonedDate = new Date(
      date.toLocaleString("en-US", {
        timeZone: "America/Sao_Paulo",
      })
    );

    timeZonedDate.setHours(Number(time), 0, 0);

    const scheduleRequest = {
      orgId: currentProduct?.organization._id,
    };

    const saleRequest = {
      total: (currentProduct?.price || 1) * counter,
      startDate: subHours(timeZonedDate, 3),
      saleProducts: currentProduct?._id,
      organization: currentProduct?.organization._id,
      amount: counter,
    };

    if (!token || !user?.user) {
      dispatch(
        saveSale({
          ...saleRequest,
          startDate: saleRequest.startDate.toISOString(),
        })
      );

      return navigate("/compra");
    }

    if (!shopCart?.data?.items?._id) {
      await createShopCart({
        ...saleRequest,
      });
    } else {
      await dispatch(
        addShopCart({
          ...saleRequest,
          cartId: shopCart.data.items._id,
        })
      );
    }

    navigate("/compra", {
      state: {
        schedule: scheduleRequest,
        sale: saleRequest,
        address: organizationAddress(),
        product: currentProduct,
      },
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!currentProduct && !loading) {
      handleToast(true, "Produto não encontrado.");
      navigate("/");
    }
  }, [currentProduct, loading, navigate]);

  useEffect(() => {
    if (debouncedCalendar) {
      checkAvailability(debouncedCalendar);
    }
  }, [checkAvailability, debouncedCalendar]);

  useEffect(() => {
    if (calendarValue) {
      setLoadingTimes(true);
      setTime(0);
    } else {
      setLoadingTimes(false);
    }
  }, [calendarValue]);

  if (loading || !currentProduct) {
    return <LoadingPage />;
  }

  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <Breadcrumb product={currentProduct} />

      <Wrapper>
        <Container>
          <Row>
            <Left>
              <ProductInfo className="product-info">
                <ImageComponent className='image-component'>
                  {selectedImage && (
                    <img
                      src={`data:image/png;base64, ${selectedImage?.image64}`}
                      alt="product"
                    />
                  )}
                  {!selectedImage && <img src={NoImage} alt="default" />}
                  <ImageButtonWrapper>
                    {currentProduct?.images?.map((item, index) => (
                      <button onClick={() => setSelectedImage(item)} key={index}>
                        <img
                          src={`data:image/png;base64, ${item?.image64}`}
                          alt="product"
                        />
                      </button>
                    ))}
                  </ImageButtonWrapper>
                </ImageComponent>
                <ProductData>
                  <h3 className="title">{currentProduct?.title}</h3>

                  <span className="price">
                    {currentProduct?.installments || 1}x de{" "}
                    {formatPrice(
                      (currentProduct?.price || 0) /
                        (currentProduct?.installments || 1)
                    )}
                  </span>
                  <small>{formatPrice(currentProduct?.price || 0)}</small>
                  <div className="company">
                    <span>
                      Vendido por:{" "}
                      <strong>{currentProduct?.organization.name}</strong>
                    </span>
                    <span>
                      Localização: <strong>{organizationAddress()}</strong>
                    </span>
                    {!!distance && (
                      <span>
                        Distância: <strong>{distance} km</strong>
                      </span>
                    )}
                  </div>
                  <Counter
                    counter={counter}
                    setCounter={setCounter}
                    amount={currentProduct?.qtdServices || 1}
                  />
                </ProductData>
              </ProductInfo>
            </Left>
            <Right>
              <div className="price-box">
                <span className="price">
                  {formatPrice((currentProduct?.price || 0) * counter)}
                </span>
                <span className="parc">
                  {currentProduct?.installments || 1}x de{" "}
                  {formatPrice(
                    ((currentProduct?.price || 0) * counter) /
                      (currentProduct?.installments || 1)
                  )}
                </span>
              </div>
              <div className="calendar">
                <h4>Selecione uma data válida</h4>
                <Calendar
                  onChange={setCalendarValue}
                  value={calendarValue}
                  minDate={new Date()}
                  prev2Label={null}
                  next2Label={null}
                  showNeighboringMonth={false}
                />
                <Time
                  time={time}
                  setTime={setTime}
                  possibleTimes={availableTime}
                  loading={loadingTimes}
                />
                <button
                  className="bt-calendar"
                  onClick={() => onSubmit()}
                  disabled={loading || loadingTimes || !calendarValue || !time}
                >
                  {loading ? <Spinner /> : "Agendar Horário"}
                </button>
              </div>
            </Right>
          </Row>
          <div className="info">
            <h3 style={{ marginTop: 30 }}>Informações do produto</h3>
            <TabsInfo
              description={currentProduct.description}
              technicalInformation={currentProduct.technicalInformation}
              comments={comments}
            />
          </div>
        </Container>

        <div className="col-12 title-section" style={{ padding: "0 20px" }}>
          <h3>Outros Produtos</h3>
          <Link to="/">
            <span>Ver Mais</span>
          </Link>
        </div>

        <div>
          <Produto />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default ViewProduct;
