import Header from "../../components/Header";
import TopSales from "../../components/product/TopSales";
import Produtos from "../../components/ProductsList";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { SwiperContainer } from "./styles";

const Home = () => {
  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <SwiperContainer>
        <div className="title">
          <h3>Produtos</h3>
          <Link to="/produtos">Ver mais</Link>
        </div>
        <div className="row">
          <Produtos />
        </div>
        <div className="title">
          <h3>Mais Vendidos</h3>
          <Link to="/produtos">Ver mais</Link>
        </div>
        <div className="row">
          <TopSales />
        </div>
      </SwiperContainer>
      <Footer />
    </>
  );
};

export default Home;
