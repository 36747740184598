import { useState, useEffect } from "react";
import Select from "react-select";

import Table from "./Table";

import api from "../../../services/api";

import { AddVehicle, Container, InputContainer } from "./styles";
import { handleToast } from "../../../utils/handleToast";
import { useAppSelector } from "../../../hooks";
import { userSelector } from "../../../store/user";

interface IReactSelect {
  value: any;
  label: string;
}

const Vehicles: React.FC<any> = ({ vehicles, setVehicles }) => {
  const [placa, setPlaca] = useState("");
  const [carBrand, setCarBrand] = useState<IReactSelect | null>();
  const [carModel, setCarModel] = useState<IReactSelect | null>();
  const [carYear, setCarYear] = useState<IReactSelect | null>();
  const [, setData] = useState(undefined as any);

  const [brands, setBrands] = useState<IReactSelect[]>();
  const [models, setModels] = useState<IReactSelect[]>();
  const [years, setYears] = useState<IReactSelect[]>();

  const [disabled, setDisabled] = useState({ model: true });

  const user = useAppSelector(userSelector);

  useEffect(() => {
    async function fetch() {
      const res = await api.get("/carmodel");

      setData(res.data);

      setDisabled({ model: true });

      setModels(
        res.data.data.items.map((item: any) => {
          return {
            value: item,
            label: item.name,
          };
        })
      );
    }

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (models?.length && user?.data?.user?._id) {
        const res = await api.get(`/user/${user?.data?.user?._id}/car`);

        setVehicles(
          res?.data?.data?.item?.map((vehicle: any) => ({
            brand: vehicle.brand,
            plate: vehicle.plate,
            year: vehicle.year,
            model: models?.find((model) => model.value._id === vehicle.model)
              ?.label,
            _id: vehicle._id,
          }))
        );
      }
    };

    fetch();
  }, [models, setVehicles, user?.data?.user?._id]);

  useEffect(() => {
    setBrands([]);
    setYears([]);

    setCarBrand(null);
    setCarYear(null);

    if (carModel) {
      setBrands([
        { value: carModel.value.carBrand, label: carModel.value.carBrand },
      ]);

      setYears([
        { value: carModel.value.carYear, label: carModel.value.carYear },
      ]);

      setDisabled({ model: false });
    }
  }, [carModel]);

  const findVehicleIndex = (vehicle: any) => {
    return vehicles.findIndex((v: any) => {
      return (
        vehicle.plate.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") ===
        v.plate.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")
      );
    });
  };

  const addVehicle = () => {
    const newVehicle = {
      year: carYear?.value,
      model: carModel?.value,
      brand: carBrand?.value,
      plate: placa,
    };

    if (findVehicleIndex(newVehicle) === -1)
      return setVehicles([...vehicles, newVehicle]);

    return handleToast(true, "Informe outra placa");
  };

  const deleteVehicle = async (id: string) => {
    await api.delete(`user/${user?.data?.user?._id}/car/${id}`);

    return handleToast(false, "Veículo removido.");
  };

  const removeVehicle = (vehicle: any) => {
    const vehicleIndex = findVehicleIndex(vehicle);

    if (vehicles[vehicleIndex]._id) {
      deleteVehicle(vehicles[vehicleIndex]._id);
    }

    const tempVehicles = [...vehicles];

    if (vehicleIndex === -1) return;

    tempVehicles.splice(vehicleIndex, 1);

    setVehicles(tempVehicles);
  };

  const customStyles = {
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      minHeight: "40px",
      height: "40px",
      marginTop: "10px",
      padding: "0 8px",
      borderRadius: "5px",
      border: "1px solid #ececec",
      background: isDisabled ? "#eeeeee" : "white",
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      height: "40px",
      padding: "0 8px",
    }),

    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "40px",
    }),
  };

  return (
    <Container>
      <div className="title">
        <h3>Dados veículo</h3>
        <hr />
      </div>
      <AddVehicle>
        <InputContainer style={{ maxWidth: "263px" }}>
          <label>Placa</label>
          <input
            value={placa}
            onChange={(e) => setPlaca(e.target.value)}
            placeholder="Placa"
          />
        </InputContainer>
        <InputContainer>
          <label>Modelo</label>
          <Select
            styles={customStyles}
            onChange={(e: any) => setCarModel(e)}
            options={models}
            placeholder="Modelo"
            value={carModel}
          />
        </InputContainer>
        <InputContainer>
          <label>Montadora</label>
          <Select
            styles={customStyles}
            onChange={(e: any) => setCarBrand(e)}
            options={brands}
            placeholder="Montadora"
            value={carBrand}
            isDisabled={disabled.model}
          />
        </InputContainer>

        <InputContainer>
          <label>Ano</label>

          <Select
            styles={customStyles}
            onChange={(e: any) => setCarYear(e)}
            options={years}
            placeholder="Ano"
            value={carYear}
            isDisabled={disabled.model}
          />
        </InputContainer>
        <button
          disabled={!carYear || !placa}
          onClick={addVehicle}
          type="button"
        >
          Adicionar
        </button>
      </AddVehicle>
      <Table vehicles={vehicles} removeVehicle={removeVehicle} />
    </Container>
  );
};

export default Vehicles;
