import { PixContainer, Wrapper } from "./styles";
import MaskedInput from "react-text-mask";
import React from "react";
import { useDebouncedCallback } from "use-debounce";
import cep from "cep-promise";
import { handleToast } from "../../../utils/handleToast";
import removeAllNonNumberCharacters from "../../../utils/removeAllNonNumberCharacters";

interface IAddress {
  zipcode: string;
  street: string;
  complement: string;
  number: string;
}

interface IProps {
  cpfCnpj: string;
  setCpfCnpj: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  address: IAddress;
  setAddress: React.Dispatch<React.SetStateAction<IAddress>>;
}

const Pix: React.FC<IProps> = ({
  cpfCnpj,
  setCpfCnpj,
  name,
  setName,
  address,
  setAddress,
}) => {
  const getDebouncedCep = useDebouncedCallback(async (c) => {
    if (removeAllNonNumberCharacters(c).length === 8) {
      try {
        const res = await cep(removeAllNonNumberCharacters(c));
        setAddress({ ...address, street: res.street });
      } catch (e) {
        handleToast(true, "Não foi possível trazer CEP");
      }
    }
  }, 1000);

  return (
    <Wrapper>
      <h3>Informações pessoais</h3>
      <PixContainer>
        <input
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome"
        />
        <MaskedInput
          name="cpfCnpj"
          value={cpfCnpj}
          onChange={(e) => setCpfCnpj(e.target.value)}
          placeholder="CPF"
          mask={[
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ]}
        />
      </PixContainer>
      <h3>Endereço</h3>
      <div className="address">
        <MaskedInput
          name="zipcode"
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
          placeholder="CEP"
          type="tel"
          onChange={(e) => {
            getDebouncedCep(e.target.value);
            setAddress({ ...address, zipcode: e.target.value });
          }}
          value={address.zipcode}
        />
        <input
          name="street"
          placeholder="Rua"
          onChange={(e) => setAddress({ ...address, street: e.target.value })}
          value={address.street}
        />
        <div>
          <input
            name="number"
            placeholder="Número"
            type="tel"
            onChange={(e) => setAddress({ ...address, number: e.target.value })}
            value={address.number}
            style={{ width: 205 }}
          />
          <input
            name="complement"
            placeholder="Complemento"
            onChange={(e) =>
              setAddress({ ...address, complement: e.target.value })
            }
            value={address.complement}
            style={{ width: 205 }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Pix;
