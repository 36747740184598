import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";

import { productSelector, productsList } from "../store/products";

interface IReq {
  rowsPerPage?: number;
  page?: number;
  category?: string;
  productType?: string;
  userId: string;
  carYear?: string;
  carModel?: string;
  carBrand?: string;
  cep?: string;
}

export function useProducts() {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector(productSelector);

  const handleProducts = useCallback(
    async (data: IReq) => {
      try {
        dispatch(productsList(data));
      } catch (error) {}
    },
    [dispatch]
  );

  return { data, loading, handleProducts };
}
