import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/breadcrumb";
import Produto from "../../components/product/ProductsSwiper";

import NoImage from "../../img/no-image-lg.png";

import {
  Container,
  EditModal,
  Left,
  ModalContainer,
  Product,
  RadioBox,
  Right,
  SaleSection,
  Spinner,
  Wrapper,
  Row,
} from "./styles";

import { handleToast } from "../../utils/handleToast";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addShopCart,
  clearShopCart,
  saveShopCart,
  userShopCart,
} from "../../store/shop-cart";

import { IProduct } from "../../store/products";
import {
  createShopCart,
  removeShopCartItem,
  updateCartItem,
} from "../../services/shop-cart.service";
import Calendar from "react-calendar";
import Time from "../../components/ViewProduct/Time";
import Counter from "../../components/Counter";
import Pix from "./PaymentMethods/Pix";
import removeAllNonNumberCharacters from "../../utils/removeAllNonNumberCharacters";
import validateCPF from "../../utils/validateCpf";
import CreditCard from "./PaymentMethods/CreditCard";
import api from "../../services/api";
import { addHours, format } from "date-fns";
import { countBy } from "lodash";
import React from "react";
import LoadingPage from "../../components/LoadingPage";
import { clearSale } from "../../store/sale";

interface IProductImage {
  path: string;
  name: string;
  mimetype: string;
  filepath: string;
  image64: string;
}

const Compra = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { state }: any = location;

  const shopCart = useAppSelector((state) => state.shopCart.data);
  const token = useAppSelector((state) => state.token);
  const user = useAppSelector((state) => state.user);
  const sale = useAppSelector((state) => state.sale);
  const addressSelector = useAppSelector((state) => state.address.data);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [loading, setLoading] = useState({
    cancel: false,
    update: false,
    submit: false,
    fetch: true,
    time: false,
    sale: true,
    products: true,
  });

  const [cancelModal, setCancelModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null as any);
  const [editModal, setEditModal] = useState(false);
  const [editProduct, setEditProduct] = useState(null as any);
  const [tipoPagamento, setTipoPagamento] = useState("pix");
  const [address, setAddress] = useState({
    zipcode: (addressSelector?.cep || addressSelector?.zipcode) as string,
    street: (addressSelector?.street || addressSelector?.rua) as string,
    complement: addressSelector?.complement as string,
    number: addressSelector?.number || "",
  });
  const [availableTime, setAvailableTime] = useState<number[]>([]);
  const [installments, setInstallments] = useState(1);
  const [totalInstallments, setTotalInstallments] = useState(1);

  const [creditCardData, setCreditCardData] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
  });

  const [name, setName] = useState(
    (user?.data?.user?.name || "") + (user?.data?.user?.lastName || "")
  );
  const [cpfCnpj, setCpfCnpj] = useState<string>("");

  const getProduct = React.useCallback(
    (productId?: string) => {
      if (!products.length) return {} as IProduct;

      if (!productId) return {} as IProduct;

      return products?.find((product: any) => product._id === productId);
    },
    [products]
  );

  useEffect(() => {
    if (
      !address.street &&
      !address.number &&
      !address.zipcode &&
      !address.complement
    ) {
      if (addressSelector) {
        setAddress({
          zipcode: (addressSelector?.cep || addressSelector?.zipcode) as string,
          street: (addressSelector?.street || addressSelector?.rua) as string,
          complement: addressSelector?.complement as string,
          number: addressSelector?.number || "0",
        });
      }
    }
  }, [address, addressSelector]);

  const checkAvailability = React.useCallback(
    async (day: Date) => {
      if (!editProduct) return;

      setLoading({ ...loading, time: true });
      const res = await api.get(
        `/organization-settings/${
          getProduct(editProduct?.saleProducts || "")?.organization._id
        }/logout`,
        {
          params: { startDate: day },
        }
      );

      const dayOfWeek = format(day, "EEEE").toLowerCase();

      const openDay = res?.data?.data?.settings?.open?.find(
        (item: any) => item.dayOfWeek === dayOfWeek
      );

      const availableDayTime = {
        start: Number(openDay.openClock.split(":")[0]),
        finish: Number(openDay.closeClock.split(":")[0]),
      };

      if (!res?.data?.data?.items?.length) {
        const tempAvailableTime = Array.from(
          { length: availableDayTime.finish - availableDayTime.start + 1 },
          (_, index) => index + availableDayTime.start
        );

        setAvailableTime(tempAvailableTime.filter((item) => item));

        return setLoading({ ...loading, time: false });
      }

      const grouped = countBy(res?.data?.data?.items, "reservedHour");

      const blockedHours: any = [];

      for (const key in grouped) {
        if (
          grouped[key] >= res?.data?.data?.settings?.simultaneousAppointments
        ) {
          blockedHours.push(key);
        }
      }

      const tempAvailableTime = Array.from(
        { length: availableDayTime.finish - availableDayTime.start },
        (_, index) => index + availableDayTime.start
      ).filter((item) => {
        return !blockedHours.find((i: any) => Number(i.split(":")[0]) === item);
      });

      setAvailableTime(tempAvailableTime.filter((item) => item));

      setLoading({ ...loading, time: false, fetch: false });
    },
    [editProduct, getProduct, loading]
  );

  useEffect(() => {
    if (!cancelModal) {
      setSelectedItem(null);
    }
  }, [cancelModal]);

  useEffect(() => {
    checkAvailability(new Date(editProduct?.startDate));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProduct?.startDate]);

  const updateCartProduct = async () => {
    setLoading({ ...loading, cancel: true });

    await updateCartItem({
      cartId: shopCart.items._id,
      product: { ...editProduct },
    });

    setLoading({ ...loading, cancel: true });

    handleToast(false, "Agendamento atualizado.");
    setEditModal(false);
  };

  const organizationAddress = (address: any) => {
    if (!address) return "";

    const { street, complement, number, district } = address;

    const validFields = [street, complement, number, district].filter(
      (item) => item
    );

    return validFields.join(", ");
  };

  const validate = useCallback(() => {
    if (!validateCPF(removeAllNonNumberCharacters(cpfCnpj))) {
      handleToast(true, "Entre com um CPF válido.");
      return false;
    }

    if (!name) {
      handleToast(true, "Entre com um nome.");
      return false;
    }

    for (const key in address) {
      if (!key) {
        handleToast(true, "Entre com o endereço");
        return false;
      }
    }

    return true;
  }, [address, cpfCnpj, name]);

  const removeItem = async () => {
    setLoading({ ...loading, cancel: true, fetch: true });

    if (!user && !token) {
      dispatch(
        saveShopCart({
          items: {
            ...shopCart.items,
            total: shopCart.items.total - Number(selectedItem?.total),
            products: shopCart.items.products.filter((product: any) => {
              return product._id !== selectedItem?._id;
            }),
          },
        })
      );

      handleToast(false, "Produto removido do carrinho.");
      setLoading({ ...loading, cancel: false, fetch: false });
      return setCancelModal(false);
    }

    await removeShopCartItem({
      id: shopCart?.items._id,
      productId: selectedItem?._id,
    });

    handleToast(false, "Produto removido do carrinho.");

    setCancelModal(false);
    await dispatch(userShopCart());
    setLoading({ ...loading, cancel: false, fetch: false });
  };

  useEffect(() => {
    console.log(state);
    if (state?.name) setName(state.name);
    if (state?.cpfCnpj) setCpfCnpj(state.cpfCnpj);
    if (state?.billingType) {
      if (state?.billingType === "CREDIT_CARD") setTipoPagamento("card");
      else setTipoPagamento(state?.billingType.toLowerCase());
    }
    if (state?.address) setAddress(state.address);
    if (state?.product?.installments)
      setTotalInstallments(state?.product?.installments);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const submit = useCallback(async () => {
    if (!validate()) {
      return;
    }

    setLoading({ ...loading, submit: true });

    if (!user && !token) {
      handleToast(false, "Você deve estar logado para finalizar a compra.");
      return navigate("/login");
    }

    if (tipoPagamento === "pix") {
      return navigate("/pagamento", {
        state: {
          pix: true,
          name,
          cpfCnpj,
          userId: user.data.user._id,
          customerEmail: user.data.user.email,
          address,
        },
      });
    } else {
      try {
        const response = await api.post(
          `create-billing/parcel?orgId=${shopCart?.items.organization._id}`,
          {
            data: {
              billingType: "CREDIT_CARD",
              customer: user?.data?.user?.costumerId,
              dueDate: addHours(new Date(), 2),
              value: shopCart?.items?.total,
              description: `${shopCart?.items?._id}`,
              externalRefence: "",
              installmentCount: installments > 1 ? installments : undefined,
              installmentValue:
                installments > 1
                  ? shopCart?.items?.total / installments
                  : undefined,
              creditCard: {
                holderName: name,
                number: creditCardData?.cardNumber,
                expiryMonth: creditCardData?.expiry?.split("/")[0],
                expiryYear: creditCardData?.expiry?.split("/")[1],
                ccv: creditCardData?.cvc,
              },
              creditCardHolderInfo: {
                name,
                email: user?.data?.user?.email,
                cpfCnpj,
                postalCode: address.zipcode,
                addressNumber: address.number,
                addressComplement: address.complement || null,
                mobilePhone: user?.data?.user?.celDDD + user?.data?.user?.cel,
                phone: user?.data?.user?.celDDD + user?.data?.user?.cel,
              },
            },
          }
        );

        if (!(response as any).error) {
          navigate("/confirmacaoagendamento");
          dispatch(clearShopCart());
        } else {
          handleToast(true, "Não foi possível completar o pagamento.");
        }
      } catch (e) {
        handleToast(true, "Não foi possível completar o pagamento.");
      } finally {
        setLoading({ ...loading, submit: false });
      }
    }

    setLoading({ ...loading, submit: false });
  }, [
    dispatch,
    address,
    cpfCnpj,
    creditCardData?.cardNumber,
    creditCardData?.cvc,
    creditCardData?.expiry,
    installments,
    loading,
    name,
    navigate,
    shopCart?.items?._id,
    shopCart?.items?.organization._id,
    shopCart?.items?.total,
    tipoPagamento,
    token,
    user,
    validate,
  ]);

  useEffect(() => {
    const fetch = async () => {
      if (user?.data?.user?._id) {
        try {
          setLoading((prevState) => ({ ...prevState, fetch: true }));
          await Promise.all([dispatch(userShopCart())]);
        } catch (e: any) {
          handleToast(true, e);
          setLoading((prevState) => ({ ...prevState, fetch: false }));
        } finally {
          setLoading((prevState) => ({ ...prevState, fetch: false }));
        }
      }
    };

    fetch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const fetch = async () => {
      if (shopCart?.items?.products?.length && !loading.fetch) {
        const promiseArray = [];

        for (const item of shopCart?.items?.products) {
          promiseArray.push(api.get(`/products-sale/${item.saleProducts}`));
        }

        const res = await Promise.all(promiseArray);

        const items = res.map((item) => item?.data?.data?.item || null);

        setProducts(items);
        setLoading((prevState) => ({ ...prevState, products: false }));
      }

      if (!loading.fetch && !shopCart?.items?.products?.length) {
        setLoading((prevState) => ({ ...prevState, products: false }));
      }
    };

    fetch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.fetch]);

  useEffect(() => {
    const fetch = async () => {
      if (sale.data && !loading.fetch && !loading.products) {
        setLoading((prevState) => ({ ...prevState, sale: true }));
        if (shopCart?.items && shopCart?.items?.products[0]) {
          const cartProduct = products[0];

          if (
            cartProduct &&
            cartProduct.organization?._id &&
            cartProduct.organization._id !== sale.data?.organization
          ) {
            navigate("/item-produto/" + sale.data.saleProducts);
            dispatch(clearSale());

            return handleToast(
              true,
              "Carrinho deve ser composto por itens do mesmo local."
            );
          }
        }

        if (!shopCart?.items?._id) {
          await createShopCart({
            ...sale.data,
          });
          dispatch(clearSale());
          await dispatch(userShopCart());
        } else {
          await dispatch(
            addShopCart({
              ...sale.data,
              cartId: shopCart.items._id,
            })
          );
          dispatch(clearSale());
          await dispatch(userShopCart());
        }
        setLoading((prevState) => ({ ...prevState, sale: false }));
      }

      if (!sale.data) {
        setLoading((prevState) => ({ ...prevState, sale: false }));
      }
    };

    fetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading.fetch, sale.data, loading.products]);

  const renderProductImage = useCallback((images: IProductImage[]) => {
    if (!images?.length) return NoImage;

    return `data:${images[0]?.mimetype};base64,${images[0]?.image64}`;
  }, []);

  if (loading.sale || loading.fetch || loading.products) {
    return <LoadingPage />;
  }

  if (
    (!shopCart?.items || !shopCart?.items?.products?.length) &&
    !loading.fetch &&
    !loading.sale &&
    !loading.products
  ) {
    return (
      <>
        <header className="App-header">
          <Header />
        </header>

        <Breadcrumb />
        <Wrapper>
          <div className="empty">
            <h2>Seu carrinho está vazio!</h2>
            <p>Adicione itens ao carrinho para continuar.</p>
          </div>
          {/* <Produto /> */}
        </Wrapper>
        <Footer />
      </>
    );
  }

  return (
    <>
      <header className="App-header">
        <Header />
      </header>

      <section className="breadcrumbs">
        <Breadcrumb />
      </section>
      <Wrapper>
        <Container>
          <Left>
            <SaleSection>
              <h2>1 - Endereço do serviço</h2>
              <div>
                <span>
                  {organizationAddress(
                    products[0]?.organization?.address[0] || ""
                  )}
                </span>
              </div>
            </SaleSection>

            <SaleSection>
              <h2>2 - Forma de pagamento</h2>
              <Row marginBottom={'20px'}>
                <RadioBox>
                  <label htmlFor="pix">
                    <input
                      type="radio"
                      name="pix"
                      id="pix"
                      value="pix"
                      onChange={(e) => setTipoPagamento(e.target.value)}
                      checked={tipoPagamento === "pix"}
                    />
                    Pix
                  </label>
                </RadioBox>
                <RadioBox>
                  <label htmlFor="card">
                    <input
                      type="radio"
                      name="card"
                      id="card"
                      value="card"
                      onChange={(e) => setTipoPagamento(e.target.value)}
                      checked={tipoPagamento === "card"}
                    />
                    Cartão
                  </label>
                </RadioBox>
              </Row>
              {tipoPagamento === "pix" && (
                <Pix
                  cpfCnpj={cpfCnpj}
                  setCpfCnpj={setCpfCnpj}
                  name={name}
                  setName={setName}
                  address={address}
                  setAddress={setAddress}
                />
              )}
              {tipoPagamento === "card" && (
                <CreditCard
                  data={creditCardData}
                  setData={setCreditCardData}
                  name={name}
                  cpfCnpj={cpfCnpj}
                  installments={installments}
                  totalInstallments={totalInstallments}
                  setInstallments={(v) => setInstallments(v)}
                  products={shopCart?.items?.products}
                  value={shopCart?.items?.total}
                  setName={setName}
                  setCpfCnpj={setCpfCnpj}
                  address={address}
                  setAddress={setAddress}
                />
              )}
            </SaleSection>
            <SaleSection>
              <h2>3 - Agendamento e Serviço</h2>
              {shopCart?.items?.products?.map((product: any) => {
                return (
                  <Product key={product._id}>
                    <img
                      src={renderProductImage(
                        getProduct(product?.saleProducts || "")?.images as any
                      )}
                      alt="Imagem do produto"
                    />
                    <div className="description">
                      <h3>{getProduct(product.saleProducts || "")?.title}</h3>
                    </div>
                    <div className="buttons">
                      <button
                        onClick={() => {
                          setEditModal(true);
                          setEditProduct(product);
                        }}
                      >
                        Editar agendamento
                      </button>
                      <button
                        onClick={() => {
                          setSelectedItem(product);
                          setCancelModal(true);
                        }}
                      >
                        Remover item
                      </button>
                    </div>
                  </Product>
                );
              })}
            </SaleSection>
          </Left>
          <Right>
            <h3>Forma de pagamento</h3>
            <div className="line">
              <span>
                {tipoPagamento === "pix" ? "Pix" : "Cartão de crédito"}
              </span>
            </div>
            <div className="section">
              <div className="text">
                <div className="line">
                  <span>Itens</span>
                  <span>
                    {shopCart?.items?.total.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
              </div>
              <div className="section">
                <div className="text">
                  <div className="line">
                    <p>Total:</p>
                    <p>
                      {shopCart?.items?.total.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button onClick={() => submit()} disabled={loading.submit}>
              {loading.submit ? <Spinner /> : "Confirmar agendamento"}
            </button>
          </Right>
        </Container>
        <Produto />
      </Wrapper>
      <Footer />
      <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
        <ModalContainer>
          <h3 style={{ textAlign: "center" }}>
            Tem certeza que deseja cancelar este agendamento? Este horário pode
            não estar mais disponível no futuro.
          </h3>
          <div className="buttons">
            <button onClick={() => removeItem()} disabled={loading.cancel}>
              {loading.cancel ? <Spinner /> : "Confirma"}
            </button>
            <button
              onClick={() => setCancelModal(false)}
              disabled={loading.cancel}
            >
              Cancelar
            </button>
          </div>
        </ModalContainer>
      </Modal>
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <ModalContainer>
          <h4>Selecione uma data válida</h4>
          <EditModal>
            <div className="calendar" style={{ display: "block" }}>
              <Calendar
                onChange={(value: any) =>
                  setEditProduct({ ...editProduct, startDate: value })
                }
                value={new Date(editProduct?.startDate)}
                minDate={new Date()}
                prev2Label={null}
                next2Label={null}
                showNeighboringMonth={false}
              />
              <Time
                time={new Date(editProduct?.startDate).getHours()}
                possibleTimes={availableTime}
                loading={loading.time}
                setTime={(value: number) => {
                  const date = new Date(editProduct?.startDate);
                  date.setHours(value);
                  date.setMinutes(0);

                  setEditProduct({ ...editProduct, startDate: date });
                }}
              />
            </div>
            <h5 className="qty">Quantidade</h5>
            <Counter
              counter={editProduct?.amount}
              setCounter={(amount) =>
                setEditProduct({ ...editProduct, amount })
              }
              amount={getProduct(editProduct?.saleProducts)?.qtdServices || 1}
            />
          </EditModal>
          <div className="buttons">
            <button
              disabled={loading.cancel || loading.time}
              onClick={() => updateCartProduct()}
            >
              {loading.cancel ? <Spinner /> : "Confirma"}
            </button>
            <button
              onClick={() => setEditModal(false)}
              disabled={loading.cancel}
            >
              Cancelar
            </button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
};
export default Compra;
