import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "./Login.css";
import Formulario from "../../components/Login";

interface IProps {
  prevRoute?: string;
}

const Login = ({ prevRoute }: IProps) => {
  return (
    <>
      <header className="App-header">
        <Header />
      </header>
      <main>
        <div className="container">
          <div className="row">
            <Formulario prevRoute={prevRoute} />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};
export default Login;
