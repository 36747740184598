import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  /* overflow: hidden; */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  margin-top: 20px;
`;

export const Header = styled.header`
  width: 100%;
  background: var(--green);
  color: white;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 5px;
  column-gap: 20px;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  span {
    color: white;
    font-size: 16px;
  }
`;

export const Body = styled.div`
  width: 100%;
  background: white;
  display: flex;
  padding: 20px;
  gap: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  a {
    color: #e64c15 !important;
    font-size: 18px;
    text-decoration: underline !important;
    margin-top: 10px;
    font-weight: 700;
    display: inline-block;
  }

  .center {
    > span {
      font-size: 16px;
      display: block;

      & + span {
        margin-top: 5px;
      }

      b {
        color: black;
        font-weight: 700;
      }
    }
  }
`;

export const Left = styled.div`
  > span {
    font-size: 16px;
    display: block;
    color: #888888;

    & + span {
      margin-top: 5px;
    }

    b {
      color: black;
      font-weight: 700;
    }
  }
`;

export const Product = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;

  img {
    height: 150px;
    width: 120px;
    object-fit: cover;
  }

  p {
    font-size: 20px;
    max-width: 350px;
    margin: 0;
    color: #e64c15;
  }
`;

export const Right = styled.div`
  margin-left: auto;
  width: 100%;
  max-width: 220px;
  align-self: center;

  a,
  button {
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    font-size: 18px;
  }

  display: flex;
  flex-direction: column;
  gap: 10px;

  .detalhes {
    background: #e64c15;
    color: white;
    border: 1px solid #e64c15;
  }

  .repetir {
    background: white;
    color: var(--green) !important;
    border: 1px solid var(--green);
    text-decoration: none !important;
    font-weight: 400;
    margin: 0;
  }

  .cancelar {
    color: #bf0000;
    border: 1px solid #bf0000;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 30px 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  border-radius: 5px;

  textarea {
    height: 100px;
    resize: none;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 100%;
    padding: 20px;
  }

  h3 {
    font-size: 20px;
    width: fit-content;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  > div.buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    button {
      height: 40px;
      width: 100%;
      border-radius: 5px;
      border: none;
      outline: none;
      color: white;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;

      :disabled {
        opacity: 0.7;
      }

      :first-child {
        background: var(--green);
      }

      :last-child {
        background: red;
      }
    }
  }
`;
