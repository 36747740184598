import { useCallback, useState } from "react";

import { Formik } from "formik";
import * as yup from "yup";

import Form from "./Form";
import api from "../../../services/api";
import { useAppSelector } from "../../../hooks";
import { userSelector } from "../../../store/user";
import { handleToast } from "../../../utils/handleToast";
import { useNavigate } from "react-router-dom";

interface IUserAddress {
  street: string;
  state: string;
  city: string;
  country: string;
  zipcode: string;
  number: string | number;
  complement: string;
  district: string;
  user: string;
  _id: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Entre com um nome"),
  email: yup.string().email("Entre com um e-mail válido"),
  address: yup.array().of(
    yup.object().shape({
      rua: yup.string().required("Entre com um rua"),
      cep: yup.string().required("Entre com um CEP válido"),
      numero: yup.number(),
      complemento: yup.string(),
      state: yup.string().required("Entre com um estado"),
      district: yup.string().required("Entre com o bairro"),
      city: yup.string().required("Entre com a cidade"),
    })
  ),
});

const CadastroDadosPessoais: React.FC = () => {
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { user } = useAppSelector(userSelector);

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (formData: any) => {
      setLoading(true);
      const addressPromise: any = [];
      const vehiclesPromise = [];

      for (const address of formData.address) {
        const userData = {
          street: address.rua,
          complement: address.complemento,
          zipcode: address.cep,
          number: address.numero,
          district: address.district,
          state: address.state,
          city: address.city,
        };

        if (address._id) {
          addressPromise.push(
            api.patch(`update-address/${address._id}`, {
              data: userData,
            })
          );
        } else {
          addressPromise.push(
            api.patch(`user/${user._id}/address`, {
              data: userData,
            })
          );
        }
      }

      const removeArray = user?.address?.filter((address: IUserAddress) => {
        return !formData?.address?.find(
          (formadd: IUserAddress) => formadd?._id === address?._id
        );
      });

      for (const address of removeArray) {
        addressPromise.push(api.delete(`delete-address/${address._id}`));
      }

      for (const vehicle of vehicles) {
        vehiclesPromise.push(
          api.post(`user/${user?._id}/car`, { data: vehicle })
        );
      }

      try {
        await Promise.all([...addressPromise, ...vehiclesPromise]);
        handleToast(false, "Perfil atualizado.");
        navigate(0);
      } catch (e) {
        handleToast(true, "Não foi possível atualizar perfil.");
      } finally {
        setLoading(false);
      }
    },

    [vehicles, user, navigate]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: user?.name || "",
        email: user?.email || "",
        address: user?.address?.length
          ? user?.address?.map((userAddress: any) => ({
              _id: userAddress?._id || "",
              rua: userAddress?.street || "",
              cep: userAddress?.zipcode || "",
              numero: userAddress?.number || "",
              complemento: userAddress?.complement || "",
              district: userAddress?.district || "",
              state: userAddress?.state || "",
              city: userAddress?.city || "",
            }))
          : [
              {
                _id: "",
                rua: "",
                cep: "",
                numero: "",
                complemento: "",
                district: "",
                state: "",
                city: "",
              },
            ],
      }}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form
          vehicles={vehicles}
          setVehicles={setVehicles}
          loading={loading}
          {...props}
        />
      )}
    </Formik>
  );
};
export default CadastroDadosPessoais;
