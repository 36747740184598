import ReactLoading from "react-loading";
import Footer from "../Footer";
import Header from "../Header";

import { Wrapper } from "./styles";

const LoadingPage: React.FC = () => {
  return (
    <>
      <Header loadingPage />
      <Wrapper>
        <ReactLoading
          type={"bars"}
          color={"#1ea418"}
          width="100px"
          height="100px"
        />
      </Wrapper>
      <Footer loadingPage />
    </>
  );
};

export default LoadingPage;
